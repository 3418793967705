import BackendService from './BackendService';

export default class PropertyService extends BackendService {

    constructor() {
        super();
    }


    getPropertyUser(/*property_id*/) {

        return this.httpCall('users');

        //return this.httpCall('property' + property_id + '/users');
    }
    getPropertyUserAll(/*property_id*/) {

        return this.httpCall('users',{
            params: {
                status: 'all'
            },
        });

        //return this.httpCall('property' + property_id + '/users');
    }

    getUsersByProperty(/*property_id*/) {

        return this.httpCall('users',{
            params: {
                status: 'all'
            },
        });

        //return this.httpCall('property' + property_id + '/users');
    }

    getUserId(user_id) {

        return this.httpCall('user/'+user_id);

        //return this.httpCall('property' + property_id + '/users');
    }

    createUser(user) {

        return this.httpCall('user',{
            method: 'POST',
            data: {
                email: user.email.toLowerCase(),
                first_name: user.first_name,
                last_name:  user.last_name,
                is_admin:  user.is_admin,
                lada: user.lada,
                phone: user.phone,
                password: user.password,
                lada_cell:user.lada_cell,
                cellphone:user.cellphone
            },
        });

    }

    createUserRoles(user_id, property) {

        return this.httpCall('user/'+user_id+'/permissions',{
            method: 'POST',
            data: {
                sections: property.roles,
                property: property.property,
            },
        });

    }
    updateUserRoles(user_id, property) {
        return this.httpCall('user/'+user_id+'/permission/'+property.permission_id,{
            method: 'PUT',
            data: {
                sections: property.roles,
                property: property.property,
            },
        });

    }

    daleteUserRoles(user_id, permission_id) {
        return this.httpCall('user/'+user_id+'/permission/'+permission_id,{
            method: 'DELETE',
            data: {
            },
        });

    }

    updateStatusUser(id, status) {
        //console.log('user_info', user);

        return this.httpCall('user/'+id,{
            method: 'PUT',
            data: {
                status: status
            },
        });

    }

    updateUser(user) {
       //console.log('user_info', user);
        let is_data = {
            email: user.email.toLowerCase(),
            first_name: user.first_name,
            is_admin:  user.is_admin,
            last_name:  user.last_name,
            lada: user.lada,
            phone: user.phone,
            lada_cell:user.lada_cell,
            cellphone:user.cellphone
        };

        if(user.password!==''){
                is_data.password = user.password
        }



        return this.httpCall('user/'+user._id,{
            method: 'PUT',
            data: is_data,
        });

    }
    deleteUser(user_id) {

        return this.httpCall('user/'+user_id,{
            method: 'delete'
        });

    }


    getProperties(/*property_id*/) {

        return this.httpCall('properties');

        //return this.httpCall('property' + property_id + '/users');
    }
    getPropertyId(property_id) {

        return this.httpCall('property/'+property_id);

        //return this.httpCall('property' + property_id + '/users');
    }

    createProperty(property) {

        return this.httpCall('property',{
            method: 'POST',
            data: property,
        });

    }
    updateProperty(property) {
        //console.log('user_info', user);

        return this.httpCall('property/'+property._id,{
            method: 'PUT',
            data: property,
        });
    }
    updateIntegrationProperty(property_id, data) {
        //console.log('user_info', user);

        return this.httpCall('property/'+property_id,{
            method: 'PUT',
            data: data,
        });
    }
    updateConfigProperty(property_id, data) {
        //console.log('user_info', user);

        return this.httpCall('property/'+property_id,{
            method: 'PUT',
            data: data,
        });
    }

    updateDataProperty(property_id, data) {
        //console.log('user_info', user);

        return this.httpCall('property/'+property_id,{
            method: 'PUT',
            data: data,
        });
    }



    updatePolitics(data, property_id) {
        //console.log('user_info', user);

        return this.httpCall('property/'+property_id,{
            method: 'PUT',
            data: data,
        });

    }
    deleteProperty(property_id) {

        return this.httpCall('property/'+property_id,{
            method: 'delete'
        });

    }
    uploadLogo(property_id, data, action) {

        return this.httpCall('property/'+property_id, {
            method: action,
            data: data,
            headers: { 'Content-Type': 'multipart/form-data' }
        });

    }



}
