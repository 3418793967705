

const singleName = 'movimiento';
const pluralName = 'movimientos';
const singleNameMay = 'Movimiento';
const pluralNameMay = 'Movimientos';





export const logTranslate = {
    es: {


        index: {
            home: 'Inicio',
            title : pluralNameMay,
            type: 'Acción',
            date: 'Fecha',
            date_apply: 'Fecha aplicación',
            time: 'Hora',
            user: 'Usuario',
            id: '# Identificador',
            actions: 'Acciones',
            name: 'Nombre',
            room: 'Habitación',
            rateplan: 'Contrato',

            search: 'Buscar '+pluralName,
            filter_date: 'Rango de fechas',
            filter_date_apply: 'Rango de fechas de aplicación',
            filter_action: 'Acción',
            filter_user: 'Usuario',
            filter_id: 'ID'
        },
        show:{
            modal: '¡Operación exitosa!',
            msg: singleNameMay+' guardado'
        },

        text:{
            name: 'Nombre',
            date: 'Fecha',
            cod: 'Código',
            vigency: 'Vigencia',
            is_public: 'Abierto a publico',
            meal_plan: 'Tipo de plan',
            currency: 'Moneda',
            order: 'Orden',
            description: 'Descripción',



        }

    },

    en: {
    },
};