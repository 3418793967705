import Vue from 'vue';
import Vuex from 'vuex';
import * as auth from "@/store/modules/auth.js";
// import { mutations } from './mutations';
// import { getters } from './getters';
// import { actions } from './actions';


Vue.use(Vuex);

const store = new Vuex.Store({
    // mutations,
    // getters,
    // actions,
    // strict: true
    modules: {
      auth,
    },
    state: {
      layout: "dashboard-layout",
      loading: false,
      notification: ""
    },
    mutations: {
      SET_NOTIFICATION(state, payload) {
        state.notification = Object.assign({}, payload);
      },
      CLEAR_NOTIFICATION(state, payload) {
        state.notification = { tipo: "", message: "" };
      },
      SET_LOADING(state, payload) {
        state.loading = payload;
      },
      SET_LAYOUT(state, newLayout) {
        state.layout = newLayout;
      }
    },
    actions: {},
    getters: {}
});

Vue.prototype.$store = store;

export default store;
