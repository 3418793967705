import SettingsBase from '../classes/SettingsBase';
import es from 'vee-validate/dist/locale/es'
import en from 'vee-validate/dist/locale/en'
// Importa VeeValidate y el Validator
import Vue from 'vue'
import VeeValidate, { Validator } from "vee-validate";
Vue.use(VeeValidate);



export default class Settings extends SettingsBase {

    constructor() {
        super();
    }

    hasPassedIntro() {
        return this.getValue('USER_PASSED_INTRO');
    }

    setHasPassedIntro() {
        return this.setValue('USER_PASSED_INTRO', true);
    }

    isLoggedIn() {

       //console.log('isLoggedIn', this.getValue('LOGGIN_STATUS'));
        //return this.getValue('LOGGIN_STATUS') && this.getValue('TOKEN') !== '';

        return this.getValue('LOGGIN_STATUS');
    }

    setLoggedIn(token = '') {
        this.setValue('LOGGIN_STATUS', true);
        this.setValue('TOKEN', token);
    }

    setLogdedOut() {
      this.setValue('USER', '');
      this.removeSetting('USER', '');
      localStorage.removeItem("app_settings");
      localStorage.removeItem("properties");
      localStorage.removeItem("property");
      localStorage.removeItem("ryp");
      this.setValue('TOKEN', '');
      this.setValue('LOGGIN_STATUS', false);
    }

    getCurrentUser() {
        return this.getValue('USER');
    }
    getTokenUser() {
            let info_user = JSON.parse(localStorage.getItem('app_settings'));
            let response = info_user.find(item => item.key==='USER').value;
            //console.log('response', response)

            return response;
    }
    getTimezone(){

        return 'America/Mexico_City';
    }
    change_locale(locale){

        //console.log('locale', locale)
        localStorage.setItem('locale', locale);

        switch (locale) {
            case 'es':
                Validator.localize("es", es);
                break;
            case 'en':
                Validator.localize("en", en);
                break;
        }
    }
}