import Vue from 'vue'
import {i18n} from "./langs/index.js";
import LightBootstrap from './light-bootstrap-main'
// Plugins
import App from './App.vue'
import store from './store'
import es from 'vee-validate/dist/locale/es'
import en from 'vee-validate/dist/locale/en'
// Importa VeeValidate y el Validator
import VeeValidate, { Validator } from "vee-validate";
// router setup
import router from './routes/routes'
import { apiClient } from "@/services/ApiService.js";




if(localStorage.getItem('locale')){
    // console.log('check storage', localStorage.getItem('locale'))
    if(localStorage.getItem('locale')==='es'){
        Validator.localize("es", es);
    }else{
        Validator.localize("en", en);
    }
    i18n.locale = localStorage.getItem('locale');
}else{
    localStorage.setItem('locale', i18n.locale)
}

// plugin setup
Vue.use(LightBootstrap)
Vue.use(VeeValidate);

// Ponemos token para enviarlo siempre
const token = localStorage.getItem("token");
if (token) {
    apiClient.defaults.headers.common["token"] = token;
}

//console.log("token", token);

apiClient.interceptors.response.use(
    response => {
        // intercept the global error
        return response;
    },
    function(error) {
        let originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            // if the error is 401 and hasent already been retried
            console.log("intercepta", error);
            store.commit("SET_LOADING", false);
            store.commit("AUTH_LOGOUT");
            router.push("/login");

            // originalRequest._retry = true // now it can be retried
        }
        if (error.response.status === 404 && !originalRequest._retry) {
            originalRequest._retry = true;
            // window.location.href = '/'
            return;
        }
        // Do something with response error
        return Promise.reject(error);
    }
);



/* eslint-disable no-new */
new Vue({
    el: '#app',
    i18n,
    store,
    render: h => h(App),
    router
})
