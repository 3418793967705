<template>
  <div class="wrapper" :class="{'nav-open': $sidebar.showSidebar}">
    <side-bar>
      <user-menu></user-menu>
      <mobile-menu></mobile-menu>
      <template slot-scope="props" slot="links">
        <sidebar-item
                :link="{name: $t('sidebar.home'), icon: 'fa fa-home', path: '/admin/properties'}"
        ></sidebar-item>
        <sidebar-item
                :link="{name: $t('sidebar.room_rates'), icon: 'fa fa-bed'}"
        >
          <sidebar-item
                  v-if="currentUser.is_admin || permissions.sections[menu.roomsAndRates]"
                  :link="{name: $t('sidebar.rates'), icon: 'fa fa-file-text-o', path: '/admin/rates/rates-availability'}"
          ></sidebar-item>
          <sidebar-item :link="{name: $t('sidebar.rooms'), path: '/admin/rooms/'}"></sidebar-item>
          <sidebar-item :link="{name: $t('sidebar.contracts'), path: '/admin/contracts'}"></sidebar-item>
          <sidebar-item :link="{name: $t('sidebar.politics'), path: '/admin/annulments/'}"></sidebar-item>
          <sidebar-item
                  v-if="currentUser.is_admin || permissions.sections[menu.roomsAndRates]==2"
                  :link="{name: $t('sidebar.inventory_availability'), path: '/admin/rates/inventory-availability'}"
          ></sidebar-item>
          <sidebar-item
                  v-if="currentUser.is_admin || permissions.sections[menu.roomsAndRates]==2"
                  :link="{name: $t('sidebar.rates_restrict'), path: '/admin/rates/rates-restricts', refresh: true}"
          ></sidebar-item>
        </sidebar-item>

        <sidebar-item
                v-if="currentUser.is_admin || permissions.sections[menu.reservations]"
                :link="{name: $t('sidebar.reservations'), icon: 'fa fa-calendar', path: '/admin/reservations'}"
        ></sidebar-item>

        <sidebar-item
                v-if="currentUser.is_admin || permissions.sections[menu.marketing]"
                :link="{name: $t('sidebar.marketing'), icon: 'fa fa-bullhorn'}"
        >
          <sidebar-item :link="{name: $t('sidebar.promotions'), path: '/admin/promotions'}"></sidebar-item>
          <sidebar-item :link="{name: $t('sidebar.landings'), path: '/admin/landings'}"></sidebar-item>
          <sidebar-item :link="{name: $t('sidebar.addons'), path: '/admin/addons/'}"></sidebar-item>
        </sidebar-item>


       <sidebar-item
                v-if="currentUser.is_admin || permissions.sections[menu.marketing]"
                :link="{name: $t('sidebar.revenues'), icon: 'fa fa-line-chart'}"
        >
          <sidebar-item :link="{name: $t('sidebar.tables_dynamic'), path: '/admin/revenues'}"></sidebar-item>
        </sidebar-item>


        <sidebar-item
                v-if="currentUser.is_admin || permissions.sections[menu.propiedad]"
                :link="{name: $t('sidebar.properties'), icon: 'fa fa-building-o'}"
        >
          <sidebar-item
                  :link="{name: $t('sidebar.resume_property'), path: '/admin/property-resume/'}"
          ></sidebar-item>
          <sidebar-item :link="{name: $t('sidebar.data_property'), path: '/admin/property-data/'}"></sidebar-item>
          <sidebar-item
                  :link="{name: $t('sidebar.terms'), path: '/admin/property/terms-and-politics'}"
          ></sidebar-item>
          <sidebar-item
                  :link="{name: $t('sidebar.configurations'), path: '/admin/property/configurations'}"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item v-if="currentUser.is_admin"
                      :link="{name: $t('sidebar.users'), icon: 'fa fa-users', path: '/admin/users'}"
        ></sidebar-item>
       <sidebar-item
                v-if="currentUser.is_admin || permissions.sections[menu.marketing]"
                :link="{name: $t('sidebar.logs'), icon: 'el-icon-s-order'}"
        >
          <sidebar-item :link="{name: logItem.text, path: '/admin/logs/'+logItem.name}" v-for="logItem in arrayLogs"></sidebar-item>

        </sidebar-item>
          <sidebar-item
                  v-if="currentUser.is_admin || permissions.sections[menu.propiedad]"
                  :link="{name: $t('sidebar.integrations'), icon: 'fa fa-building-o'}"
          >
              <sidebar-item
                      v-if="currentUser.is_superadmin"
                      :link="{name: $t('sidebar.integration.ratetiger'), path: '/admin/integrations/configuration-ratetiger'}"
              ></sidebar-item>


          </sidebar-item>
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"></dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<script>
    import TopNavbar from './TopNavbar.vue'
    import ContentFooter from './ContentFooter.vue'
    import DashboardContent from './Content.vue'
    import MobileMenu from './Extra/MobileMenu.vue'
    import UserMenu from './Extra/UserMenu.vue'
    // import Settings from "src/js/helpers/Settings";
    import EventBus from "src/js/helpers/EventBus";
    import LogFunctions from "src/js/functions/LogFunctions";

    import { mapGetters } from "vuex";
    const logFunctions = new LogFunctions;

    export default {
        computed: {
            ...mapGetters(["currentUser", "selected_property","permissions"]),
        },
        components: {
            TopNavbar,
            ContentFooter,
            DashboardContent,
            MobileMenu,
            UserMenu
        },
        data() {
            return {
                user: null,
                arrayLogs: [],
                menu: {
                    roomsAndRates: 0,
                    reservations: 1,
                    marketing: 2,
                    revenue: 3,
                    conta: 4,
                    propiedad: 5,
                }
            }
        },
        created() {
            this.initPage();
        },

        mounted() {
        },
        methods: {
            toggleSidebar() {
                if (this.$sidebar.showSidebar) {
                    this.$sidebar.displaySidebar(false)
                }
            },
            /*-----------------------------Inicio-----------------------------*/

            async initPage() {
                //await this.getInfoProperty(property_id);
                let arrayLogs = logFunctions.getSections();
                this.arrayLogs = arrayLogs;

            },



        }
    }
</script>

<style lang="scss">
  .sidebar .nav .nav-item .nav-link {
    padding: 10px;
    i {
      margin-right: 10px;
    }
  }
  .sidebar
  .sidebar-wrapper
  .nav
  .nav-item
  [data-toggle="collapse"]
  ~ div
  .nav
  .sidebar-normal {
    left: 10px;
  }
  .sidebar-mini {
    span.sidebar-mini {
      display: block;
    }
  }
  span.sidebar-mini {
    display: none;
  }
</style>
