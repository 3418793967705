
export const rateTranslate = {




    es: {

        index: {
            property: 'Propiedad',
            title : 'Usuarios',
            header : 'Administrar usuarios',
            new: 'Agregar usuario',
            new_tooltip: 'Nuevo usuario'
        },
        dashboard: {
            header : 'Tarifas y disponibilidad',
            button:  'Actualizar tarifas',
            modal: '¡Operación exitosa!',
            msg: 'Tarifas actualizadas',
            legend: 'Las tarifas que se muestran son de venta y están en MXN'
        },
        section:{
            room_rates: 'Habitaciones y tarifas',
            rates_restrict: 'Tarifas y restricciones',
            inventory_available: 'Inventario y disponibilidad'
        }
    },

    en: {
    },
};