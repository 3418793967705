
export const contractTranslate = {




    es: {

        index: {
            home: 'Inicio',
            property: 'Propiedad',
            title : 'Contratos',
            new: 'Agregar nuevo contrato',
            has: 'Tienes',
            a_has_plural: 'contratos vigentes',
            a_has_singular: 'contrato vigente',
            c_has_plural: 'contratos caducados',
            c_has_singular: 'contrato caducado',
            i_has_plural: 'contratos inactivos',
            i_has_singular: 'contrato inactivo',
            search: 'Buscar contratos'
        },
        table:{
            cod: 'Código',
            plan: 'Plan de alimentos',
            vinculo: 'Vinculo',
            payment: 'Pago',

        },
        new:{
            title : 'Agregar nuevo contrato',
            header : 'Crear nuevo contrato',
            modal: '¡Operación exitosa!',
            msg: 'Contrato guardado'
        },
        edit: {
            header : 'Editar contrato',
            button:  'Actualizar contrato',
            msg: 'Contrato actualizado'

        },
        filter:{
            vigencies: 'Vigentes',
            caducated: 'Vencidos',
            inactives: 'Inactivos',
        },
        form:{
            code: 'Código contrato',
            type: 'Tipo de contrato',
            currency: 'Moneda',
            asign: 'Asignar a habitaciones',
            type_payment: 'Tipo de pago',
            politics: 'Políticas de cancelación',
            vinculo: 'Vinculo de contrato',
            link_value: 'Cantidad',
            link_type: 'Tipo de link',
            add_rooms: 'Agregar habitaciones',
            rate_base:'Tarifa base',
            extra_pax: 'Extra pax',
            simple: 'Sencilla',
            double: 'Doble',
            triple: 'Triple',
            cuadruple: 'Cuadruple',
            jr: 'Jr',
            childs: 'Niños',
            vigency: 'Vigencia',
            base_pax: 'Ocupación base'
        }

    },

    en: {
    },
};