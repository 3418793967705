
import moment from 'moment';

const removeAccents = require('remove-accents');
import LogsBase from '../classes/LogsBase';
const labelNames = [
    {
        name:'custom',
        text: 'Actualizar tarifa'
    },
    {
        name:'aggregated_values',
        text: 'Valores agregados'
    },
    {
        name:'booking_time_from',
        text: 'Booking hora inicio'
    },
    {
        name:'booking_time_to',
        text: 'Booking hora fin'
    },
    {
        name:'booking_type',
        text: 'Los clientes podrán reservar '
    },
    {
        name:'free_night_active',
        text: 'Noches gratis'
    },
    {
        name:'free_night_limit_to_stay',
        text: 'Limitar a una vez por estancia'
    },
    {
        name:'aggregated_active',
        text: 'Valores agregados'
    },

    {
        name:'discount_type',
        text: 'Tipo Descuento'
    },

    {
        name:'value_days',
        text: 'Descuento diariamente'
    },
    {
        name:'booking_days',
        text: 'Dias aplicables de descuento'
    },

    {
        name:'all_rate_configs',
        text: 'Todas las habitaciones y tarifas'
    },

    {
        name:'rate_configs',
        text: 'Aplicar a contratos'
    },

    {
        name:'min_nights',
        text: 'Mínimo de noches'
    },

    {
        name:'min_cutoff',
        text: 'Mínimo días con antelación'
    },

    {
        name:'max_cutoff',
        text: 'Máximo días con antelación'
    },

    {
        name:'max_nights',
        text: 'Máximo de noches'
    },

    {
        name:'max_reservations',
        text: 'Límite de reservaciones (inventario)'
    },
    
    {
        name:'travel_dates',
        text: 'Travel Window fechas'
    },
    {
        name:'free_night',
        text: 'Noches gratis seleccionada'
    },
    {
        name:'value',
        text: 'Porcentaje de descuento'
    },
    {
        name:'booking_to',
        text: 'Booking Window hasta'
    },
    {
        name:'booking_from',
        text: 'Booking Window desde'
    },
    {
        name:'rate_config_rules',
        text: 'Condiciones'
    },

    {
        name:'dates',
        text: 'Fechas'
    },

    {
        name:'confirmed_at',
        text: 'Hora de confirmación'
    },

    {
        name:'cancelled_at',
        text: 'Hora de cancelación'
    },

    {
        name:'price_children',
        text: 'Por niño'
    },

    {
        name:'price_jr',
        text: 'Precios Junior'
    },
    {
        name:'price_adult',
        text: 'Precios Adultos'
    },
    {
        name:'price_reservation',
        text: 'Por reservación'
    },
    {
        name:'price_room',
        text: 'Por habitación'
    },
    {
        name:'qty',
        text: 'Inventario'
    },
    {
        name:'price_type',
        text: 'Política de venta'
    },
    {
        name:'nights',
        text: 'Mínimo Noches'
    },
    {
        name:'minimum_nights',
        text: 'Aplica mínimo Noches'
    },
    {
        name:'arrive',
        text: 'Disponible el día de la llegada'
    },
    {
        name:'departure',
        text: 'Disponible el día de la salida'
    },
    {
        name:'service',
        text: 'Servicio general'
    },
    {
        name:'get_service',
        text: 'Solicitud del servicio'
    },
    {
        name:'password',
        text: 'Contraseña'
    },
    {
        name:'password',
        text: 'Contraseña'
    },
    {
        name:'email',
        text: 'Correo electrónico'
    },
    {
        name:'is_admin',
        text: 'Permiso de administrador'
    },
    {
        name:'first_name',
        text: 'Nombre'
    },
    {
        name:'cellphone',
        text: 'Número de telefono móvil'
    },
    {
        name:'lada_cell',
        text: 'Prefijo telefónico nacional para móviles'
    },
    {
        name:'lada',
        text: 'Prefijo telefónico nacional fijo'
    },
    {
        name:'last_name',
        text: 'Apellido(s)'
    },
    {
        name: 'name',
        text: 'Nombre'
    },
    {
        name: 'date',
        text: 'Fecha'
    },
    {
        name: 'cod',
        text: 'Código',
    },
    {
        name: 'vigency',
        text: 'Vigencia',
    },
    {
        name: 'meal_plan',
        text: 'Tipo de plan'
    },
    {
        name: 'is_public',
        text: 'Abierto a público',
    },
    {
        name: 'currency',
        text: 'Moneda base',
    },
    {
        name: 'order',
        text: 'Orden',
    },
    {
        name: 'description',
        text: 'Descripción',
    },

    {
        name: 'code',
        text: 'Código'
    },
    {
        name: 'phone',
        text: 'Teléfono'
    },
    {
        name: 'razon',
        text: 'Razón social'
    },
    {
        name: 'brand',
        text: 'Marca '
    },
    {
        name: 'url',
        text: 'Sitio web'
    },
    {
        name: 'phone',
        text: 'Teléfono'
    },
    {
        name: 'payment_policy',
        text: 'Políticas de pago'
    },
    {
        name: 'terms',
        text: 'Términos y condiciones'
    },
    {
        name: 'privacy',
        text: 'Políticas de privacidad del hotel'
    },
    {
        name: 'contact_name',
        text: 'Nombre para contacto'
    },
    {
        name: 'contact_phone',
        text: 'Teléfono para contacto'
    },
    {
        name: 'contact_email',
        text: 'Correo electrónico para contacto'
    },
    {
        name: 'currency_exchange',
        text: 'Tipo de cambio'
    },
    {
        name: 'time_arrive',
        text: 'Horario de llegada'
    },
    {
        name: 'time_departure',
        text: 'Horario de salida'
    },
    {
        name: 'color_header',
        text: 'Color principal (botones)'
    },
    {
        name: 'color_second',
        text: 'Color secundario (textos)'
    },
    {
        name: 'color_main',
        text: 'Color de fondo'
    },
    {
        name: 'gtm_id',
        text: 'ID de contenedor Google Tag Manager'
    },
    {
        name: 'mail_bcc',
        text: 'Listado de destinatarios ocultos'
    },
    {
        name: 'mail_cc',
        text: 'Listado de destinatarios visibles'
    },
    {
        name: 'age_infants',
        text: 'Edad máximo Infantes'
    },
    {
        name: 'age_child',
        text: 'Edad máximo Niños'
    },
    {
        name: 'age_jr',
        text: 'Edad máximo Juniors'
    },
    {
        name: 'only_adults',
        text: 'Solo para Adultos'
    },
    {
        name: 'category',
        text: 'Categoría'
    },
    {
        name: 'country',
        text: 'Pais'
    },
    {
        name: 'state',
        text: 'Estado'
    },
    {
        name: 'city',
        text: 'Ciudad'
    },
    {
        name: 'street',
        text: 'Calle'
    },
    {
        name: 'int_number',
        text: 'Número interior'
    },
    {
        name: 'ext_number',
        text: 'Número exterior'
    },
    {
        name: 'suburb',
        text: 'Colonia'
    },
    {
        name: 'zipcode',
        text: 'Código postal'
    },
    {
        name: 'b_country',
        text: 'Pais'
    },
    {
        name: 'b_city',
        text: 'Ciudad'
    },
    {
        name: 'b_state',
        text: 'Estado'
    },
    {
        name: 'b_country',
        text: 'Ciudad'
    },
    {
        name: 'b_street',
        text: 'Calle'
    },
    {
        name: 'b_int_no',
        text: 'Número interior'
    },
    {
        name: 'b_ext_no',
        text: 'Número exterior'
    },
    {
        name: 'b_suburb',
        text: 'Colonia'
    },
    {
        name: 'b_zp',
        text: 'Código postal'
    },
    {
        name: 'booking_url',
        text: 'URL Booking'
    },
    {
        name: 'included_taxes',
        text: 'Tarifas cargadas con impuestos'
    },
    {
        name: 'iva',
        text: 'IMPUESTO DEL VALOR AGREGADO (IVA)'
    },
    {
        name: 'ish',
        text: 'IMPUESTO SOBRE HOSPEDAJE(IHG)'
    },
    {
        name: 'plan_ep',
        text: 'Plan Europeo'
    },
    {
        name: 'plan_epb',
        text: 'Plan Europeo + Desayuno'
    },
    {
        name: 'plan_ai',
        text: 'Todo Incluido'
    },
    {
        name: 'pay_tdc',
        text: 'Pasarela de pago (OPENPAY)'
    },
    {
        name: 'tdc_key',
        text: 'Cliente ID (OPENPAY)'
    },
    {
        name: 'tdc_secret',
        text: 'Llave Privada (OPENPAY)'
    },
    {
        name: 'tdc_public',
        text: 'Llave Pública (OPENPAY)'
    },
    {
        name: 'pay_paypal',
        text: 'Pasarela de pago (PAYPAL)'
    },
    {
        name: 'paypal_key',
        text: 'Cliente ID (PAYPAL)'
    },
    {
        name: 'paypal_secret',
        text: 'Llave Secreta (PAYPAL)'
    },
    {
        name: 'pay_hotel',
        text: 'Pago en el hotel (verificación de tarjeta)'
    },
    {
        name: 'pay_validate',
        text: 'Verificación tarjeta de crédito / débito'
    },
    {
        name: 'pay_firstdata',
        text: 'Pasarela de pago (FIRSTDATA)'
    },
    {
        name: 'firstdata_key',
        text: 'Llave API (FIRSTDATA)'
    },
    {
        name: 'firstdata_secret',
        text: 'Llave Secreta (FIRSTDATA)'
    },
    {
        name: 'pay_stripe',
        text: 'Pasarela de pago (STRIPE)'
    },
    {
        name: 'stripe_secret',
        text: 'Llave Secreta (STRIPE)'
    },
    {
        name: 'stripe_comision',
        text: 'Comisión STRIPE'
    },
    {
        name: 'stripe_cuenta_id',
        text: 'Cliente ID (STRIPE)'
    },
    {
        name: 'rfc',
        text: 'RFC'
    },
    {
        name: 'business_name',
        text: 'RAZÓN SOCIAL'
    },
    {
        name: 'b_cfdi_usage',
        text: 'USO DE CDFI'
    },
    {
        name: 'payment_options',
        text: 'Tipo de pago'
    },
    {
        name: 'cancellation_policy',
        text: 'Políticas de cancelación'
    },
    {
        name: 'rates_list',
        text: 'Tarifas'
    },
    {
        name: 'from',
        text: 'Vigencia inicio'
    },
    {
        name: 'to',
        text: 'Vigencia fin'
    },
    {
        name: 'test',
        text: 'test'
    },
    {
        name: 'rateplan_link',
        text: 'Vinculo de contrato'
    },
    {
        name: 'view',
        text: 'Vista'
    },
    {
        name: 'type',
        text: 'Tipo'
    },
    {
        name: 'area',
        text: 'Superficie'
    },
    {
        name: 'no_rooms',
        text: 'No. de recamaras'
    },
    {
        name: 'no_baths',
        text: 'No. de baños'
    },
    {
        name: 'max_pax',
        text: 'Capacidad máxima'
    },
    {
        name: 'max_adults',
        text: 'Máximo de adultos'
    },
    {
        name: 'max_children',
        text: 'Máximo de niños'
    },
    {
        name: 'max_jr',
        text: 'Máximo de Juniors'
    },
    {
        name: 'amenities',
        text: 'Amenidades'
    },
    {
        name: 'agregate_values',
        text: 'Valores agregados'
    },
    {
        name: 'slider',
        text: 'Imagenes'
    },
    {
        name: 'images',
        text: 'Imagenes de la habitación'
    },
    {
        name: 'refundable',
        text: 'Esta tarifa no es reembolsable'
    },
    {
        name: 'cancellation_time',
        text: 'Periodo de cancelación'
    },
    {
        name: 'in_time_type',
        text: 'Dentro del periodo de penalización'
    },
    {
        name: 'in_time_value',
        text: 'Dentro del periodo de penalización valor'
    },
    {
        name: 'out_time_type',
        text: 'Fuera del periodo de penalización'
    },
    {
        name: 'out_time_value',
        text: 'Fuera del periodo de penalización valor'
    },
    {
        name: 'refundable_dates',
        text: 'Fechas no reembolsables'
    },
    {
        name: 'season_exception_dates',
        text: 'Excepciones de temporada'
    },
    {
        name: 'main',
        text: 'Establecer política de cancelación por defecto'
    },
    {
        name: 'status',
        text: 'Estado'
    },
    {
        name: 'inventory',
        text: 'Inventario'
    },
    {
        name: 'cutoff',
        text: 'Fecha límite reservación'
    },
    {
        name: 'closed_dates',
        text: 'Fecha cerrada'
    },

    {
        name: 'slug',
        text: 'Slug'
    },
    {
        name: 'rateplans',
        text: 'Contratos'
    },
    {
        name: 'title_banner',
        text: 'Título principal en imagen'
    },
    {
        name: 'text_banner',
        text: 'Título secundario en imagen'
    },
    {
        name: 'title_content',
        text: 'Título contenido'
    },
    {
        name: 'content',
        text: 'Contenido principal'
    },
    {
        name: 'title_gratitude',
        text: 'Título Agradecimientos'
    },
    {
        name: 'gratitude',
        text: 'Agradecimientos'
    },
    {
        name: 'image',
        text: 'Imagen principal'
    },
    {
        name: 'file',
        text: 'Adjuntar archivo'
    },
    {
        name: 'room_configs',
        text: 'Arreglo'
    },
    {
        name: 'single',
        text: 'Individual'
    },
    {
        name: 'double',
        text: 'Matrimonial'
    },
    {
        name: 'king',
        text: 'King Size'
    },
    {
        name: 'queen',
        text: 'Queen Size'
    },
    {
        name: 'couch',
        text: 'Sofa cama'
    },
    {
        name: 'bunk',
        text: 'Litera'
    },
    {
        name: 'linked_rateplans',
        text: 'Contratos vinculados'
    }
]

const optionsNames = [
    {
        name: 'TDD',
        text: 'Tarjeta de crédito/debito (OPENPAY)'
    },
    {
        name: 'PAYPAL',
        text: 'PayPal'
    },
    {
        name: 'ARRIVE',
        text: 'Pago en destino',
    },
    {
        name: 'VALIDATECARD',
        text: 'Verificación de tarjeta',
    },
    {
        name: 'FIRSTDATA',
        text: 'Tarjeta de crédito/debito (FIRSTDATA)'
    },
    {
        name: 'STRIPE',
        text: 'Tarjeta de crédito/debito (STRIPE)',
    }
]
const cancellation_times = [
    {
        name: '2 horas',
        value: 2,
    },
    {
        name: '6 horas',
        value: 6,
    },
    {
        name: '8 horas',
        value: 8,
    },
    {
        name: '24 horas',
        value: 24,
    },
    {
        name: '48 horas',
        value: 48,
    },
    {
        name: '72 horas',
        value: 72,
    },
    {
        name: '4 días (96 horas)',
        value: 96
    },
    {
        name: '5 días (120 horas)',
        value: 120
    },
    {
        name: '6 días (144 horas)',
        value: 144
    },
    {
        name: '1 semana (168 horas)',
        value:168
    },
    {
        name: '2 semanas (336 horas)',
        value: 336
    },
    {
        name: '3 semanas (504 horas)',
        value: 504
    },
    {
        name: '4 semanas (672 horas)',
        value: 672
    },
    {
        name: '1 mes (720 horas)',
        value: 720
    },
    {
        name: '6 semanas (1008 horas)',
        value: 1008
    },
    {
        name: '45 días (1080 horas)',
        value: 1080
    },
    {
        name: '7 semanas (1176 horas)',
        value: 1176
    },
    {
        name: '8 semanas (1344 horas)',
        value: 1344
    },
    {
        name: '60 días (1440 horas)',
        value: 1440
    },
    {
        name: '90 días (2160 horas)',
        value: 2160
    },
]
const period_types = [
    {
        _id: 1,
        type: 0,
        name: 'Nada',
        value: 0
    },
    {
        _id: 2,
        type: 1,
        name: 'Tasa fija',
        value: 500
    },
    {
        _id: 3,
        type: 2,
        name: 'Primera noche + impuestos',
        value: 1
    },
    {
        _id: 4,
        type: 2,
        name: '2 noches + impuestos',
        value: 2
    },
    {
        _id: 5,
        type: 3,
        name: '10% de la estancia + tasas',
        value: 10
    },
    {
        _id: 6,
        type: 3,
        name: '20% de la estancia + tasas',
        value: 20
    },
    {
        _id: 7,
        type: 3,
        name: '30% de la estancia + tasas',
        value: 30
    },
    {
        _id: 8,
        type: 3,
        name: '40% de la estancia + tasas',
        value: 40
    },
    {
        _id: 9,
        type: 3,
        name: '50% de la estancia + tasas',
        value: 50
    },
    {
        _id: 10,
        type: 3,
        name: '60% de la estancia + tasas',
        value: 60
    },
    {
        _id: 11,
        type: 3,
        name: '70% de la estancia + tasas',
        value: 70
    },
    {
        _id: 12,
        type: 3,
        name: '80% de la estancia + tasas',
        value: 80,
    },
    {
        _id: 13,
        type: 3,
        name: '90% de la estancia + tasas',
        value: 90
    },
    {
        _id: 14,
        type: 3,
        name: '100% de la estancia + tasas',
        value: 100
    }
]

const sections = [
    {
        _id: 1,
        name: 'rateplan',
        text: 'Contratos',
        arrayValidate : ['payment_options', 'rates_list', "linked_rateplans"],
        arrayIgnore: ['updatedAt'],
        removeInArray: [],
        removeInArrayPlus: [],
        labelTranslate:[
            {name:'from', format:'date'},
            {name:'to', format:'date'},
            {name:'payment_options', format:'arrayList'},
            {name:'rateplan_link', format:'arrayRateplanLink'},
            {name:'status', format:'boolean'}],
        getProperty: true,
        column: ['name'],
        lang: false,
        

    },
    {
        _id: 2,
        name: 'room',
        text: 'Habitaciones',
        arrayValidate : ['amenities', 'agregate_values'],
        arrayIgnore: ['rateconfig', 'updatedAt', '__v'],
        removeInArray: ["room_configs", 'slider'],
        removeInArrayPlus: [],
        labelTranslate:[
            {name:'slider', format:'array', keys:['img']},
            {name:'room_configs', format:'array', keys:['single','double','queen','king','bunk','couch']},
            {name:'amenities', format:'arrayList'},
            {name:'agregate_values', format:'arrayList'},
            {name:'status', format:'boolean'}
            
        ],
        getProperty: true,
        column: ['name'],
        lang: false,
    },


    {
        _id: 3,
        name: 'politic',
        text: 'Politicas de cancelación',
        arrayIgnore: ['updatedAt', '__v'],
        labelTranslate:[
            {name:'cancellation_time', format:'arrayPolitics', option:'time'},
            {name:'in_time_value', format:'arrayPolitics', option:'period'},
            {name:'out_time_value', format:'arrayPolitics', option:'period'},
            {name:'season_exception_dates', format:'arrayExceptions', option:'exceptions'},
            {name:'refundable_dates', format:'arrayExceptions', option:'no_refounds'},
            {name:'status', format:'boolean'},
            {name:'main', format:'boolean'},
            {name:'refundable', format:'refundable'}],
        arrayValidate : [],
        removeInArray: [],
        removeInArrayPlus: ['season_exception_dates', 'refundable_dates'],
        getProperty: true,
        column: ['name'],
        lang: false,


    },

    {
        _id: 4,
        name: 'rate',
        text: 'Tarifas',
        arrayIgnore: ['updatedAt', '__v', 'jrs_status', 'children_status', 'isBaseRate', 'max_capacity'],
        arrayValidate : [],
        removeInArray: [],
        removeInArrayPlus: ['assignments'],
        labelTranslate:[
            {name:'custom', format:'money'},
        ],
        getProperty: true,
        column: [],
        lang: false,


    },

    {
        _id: 5,
        name: 'reservation',
        text: 'Reservaciones',
        arrayIgnore: ['updatedAt', '__v', 'rate_data', 'rateconfig_data', 'rateplan_data', 'room_data', 'addons'],
        arrayValidate : [],
        removeInArray: [],
        removeInArrayPlus: [],
        labelTranslate:[
            {name:'confirmed_at', format:'date'},
            {name:'status', format:'reservationStatusList'},
        ],
        getProperty: true,
        column: ['name', 'last_name'],
        lang: false,


    },

    {
        _id: 6,
        name: 'promotion',
        text: 'Promociones',
        arrayIgnore: ['updatedAt', '__v'],
        arrayValidate : ['value_days', 'rate_configs', 'booking_days', 'travel_dates'],
        removeInArray: [],
        removeInArrayPlus: ['aggregated_values'],
        labelTranslate:[
            {name:'travel_dates', format:'arrayDates'},
            {name:'booking_from', format:'date'},
            {name:'booking_to', format:'date'},
            {name:'aggregated_active', format:'boolean'},
            {name:'free_night_active', format:'boolean'},
            {name:'all_rate_configs', format:'boolean'},
            {name:'free_night_limit_to_stay', format:'boolean'},
            {name:'booking_type', format:'optionsAdavence', keys:[1,2], response:['Por periodo de tiempo','Días y horas específicas']},
            {name:'type', format:'optionsAdavence', keys:[0,1], response:['Un solo descuento','Un descuento diariamente']},
            {name:'discount_type', format:'optionsAdavence', keys:['%'], response:['Porcentaje']},
            {name:'free_night', format:'optionsAdavence', keys:[0,1,2,3,4,5,6,7], response:['','Primera', 'Segunda', 'Tercera', 'Cuarta', 'Quinta', 'Sexta', 'Séptima']},
            {name:'booking_days', format:'arrayDay'},
            {name:'status', format:'boolean'},
            {name:'value_days', format:'arrayWeek'},
            {name:'aggregated_values', format:'arrayAggregatedValues'},
            {name:'rate_configs', format:'arrayRateConfig'}],
        getProperty: true,
        column: ['name'],
        lang: false,


    },

    {
        _id: 7,
        name: 'landing',
        text: 'Landings',
        arrayIgnore: ['updatedAt', '__v'],
        arrayValidate : ['rate_configs', 'rateplans'],
        removeInArray: ['name','text_banner','title_banner','title_content','title_gratitude','content','gratitude', 'slug'],
        removeInArrayPlus: [],
        labelTranslate:[
            {name:'name', format:'lang'},
            {name:'title_banner', format:'lang'},
            {name:'text_banner', format:'lang'},
            {name:'title_content', format:'lang'},
            {name:'title_gratitude', format:'lang'},
            {name:'content', format:'lang'},
            {name:'gratitude', format:'lang'},
            {name:'slug', format:'lang'},
            {name:'from', format:'date'},
            {name:'to', format:'date'},
            {name:'status', format:'boolean'},
            {name:'rateplans', format:'arrayRateplan'}],
        column: ['name'],
        lang: true,

    },


    {
        _id: 8,
        name: 'addon',
        text: 'Servicios extra agregados',
        arrayIgnore: ['updatedAt', '__v'],
        arrayValidate : ['price_adult', 'price_children', 'price_jr', 'price_reservation', 'price_room'],
        removeInArray: ['name','description'],
        labelTranslate:[
            {name:'name', format:'lang'},
            {name:'description', format:'lang'},
            {name:'get_service', format:'boolean'},
            {name:'service', format:'boolean'},
            {name:'arrive', format:'boolean'},
            {name:'departure', format:'boolean'},
            {name:'minimum_nights', format:'boolean'},
            {name:'from', format:'date'},
            {name:'to', format:'date'},
            {name:'price_reservation', format:'arrayList'},
            {name:'price_room', format:'arrayList'},
            {name:'price_type', format:'saleList'},
            {name:'status', format:'boolean'}
        ],
        removeInArrayPlus: [],
        getProperty: true,
        column: ['name'],
        lang: true,


    },

    {
        _id: 9,
        name: 'rule',
        text: 'Tarifas dinámicas',
        arrayIgnore: ['updatedAt', '__v'],
        arrayValidate : ['rateplans', 'dates'],
        removeInArray: [],
        removeInArrayPlus: ['rate_config_rules'],
        labelTranslate:[
            {name:'dates', format:'arrayDates'},
            {name:'status', format:'boolean'},
            {name:'rate_config_rules', format:'arrayRules'},],
        getProperty: true,
        column: ['name'],
        lang: false,


    },

    {
        _id: 10,
        name: 'property',
        text: 'Propiedad',
        arrayIgnore: ['updatedAt', '__v', 'rooms'],
        arrayValidate : ['mail_bcc', 'mail_cc'],
        removeInArray: [],
        removeInArrayPlus: [],
        labelTranslate:[
            {name:'pay_validate', format:'boolean'},
            {name:'pay_paypal', format:'boolean'},
            {name:'pay_hotel', format:'boolean'},
            {name:'pay_validate', format:'boolean'},
            {name:'pay_firstdata', format:'boolean'},
            {name:'pay_stripe', format:'boolean'},
            {name:'pay_tdc', format:'boolean'},
            {name:'included_taxes', format:'boolean'},
            {name:'only_adults', format:'boolean'},
            {name:'included_taxes', format:'boolean'},
            {name:'only_adults', format:'boolean'},
            {name:'plan_epb', format:'boolean'},
            {name:'plan_ep', format:'boolean'},
            {name:'plan_ai', format:'boolean'}],
        getProperty: true,
        column: ['name'],
        lang: false,

    },

    {
        _id: 11,
        name: 'user',
        text: 'Usuarios',
        arrayIgnore: ['updatedAt', '__v'],
        arrayValidate : [],
        removeInArray: ['permissions'],
        labelTranslate:[
            {name:'status', format:'boolean'},
            {name:'is_admin', format:'boolean'},
            {name:'permissions', format:'arrayPermission'}],
        removeInArrayPlus: [],
        getProperty: false,
        column: ['first_name', 'last_name'],
        lang: false,


    }
];
export default class LogFunctions   {
    getLabelName(slug){
        let returnText = slug;
        let itemFinded = labelNames.find(item => item.name === slug);
        if(itemFinded){
            returnText = itemFinded.text;
        }
        return returnText;
    }

    async getInputTranslate(sectionFinded, parameter, ApiService){
        let returnText = '';
        if(sectionFinded.format==='lang'){
            returnText =  this.formatLang(parameter);
        }
        if(sectionFinded.format==='date'){
            returnText =  this.formatDate(parameter);
        }
        if(sectionFinded.format==='money'){
            returnText =  '$ '+this.formatMoney(parameter);
            
        }
        if(sectionFinded.format==='boolean'){
            returnText =  this.formatBoolean(parameter);
        }
        if(sectionFinded.format==='refundable'){
            returnText =  this.formatRefundable(parameter);
        }
        if(sectionFinded.format==='array'){
            returnText =  this.formatArray(parameter, sectionFinded.keys);
        }
        if(sectionFinded.format==='arrayList'){
            returnText =  this.formatArrayTrans(parameter);
        }
        if(sectionFinded.format==='object'){
            returnText =  this.formatObject(parameter, sectionFinded.keys);
        }

        if(sectionFinded.format==='arrayPolitics'){
            returnText =  this.formatArrayPolitic(parameter, sectionFinded.option);
        }
        if(sectionFinded.format==='optionsAdavence'){
            returnText =  this.formatOptionsAdvance(parameter, sectionFinded.keys, sectionFinded.response);

        }

        if(sectionFinded.format==='arrayExceptions'){
            returnText =  this.formatArrayExceptions(parameter, sectionFinded.option);
        }
        if(sectionFinded.format==='arrayRules'){
            returnText =  await this.formatArrayRules(parameter, ApiService);
        }
        if(sectionFinded.format==='saleList'){
            returnText =  this.formatSalePolitic(parameter);
        }
        if(sectionFinded.format==='reservationStatusList'){
            returnText =  this.formatStateReservation(parameter);
        }
        

        if(sectionFinded.format==='arrayDates'){
            returnText =  this.formatArrayDates(parameter);
        }

        if(sectionFinded.format==='arrayWeek'){
            returnText =  this.formatWeek(parameter);
        }
        if(sectionFinded.format==='arrayDay'){
            returnText =  this.formatTransDay(parameter);
        }
        if(sectionFinded.format==='arrayPermission'){
             returnText =  await this.formatArrayPermission(parameter, ApiService);
        }
        if(sectionFinded.format==='arrayRateplan'){
            returnText =  await this.formatArrayRateplan(parameter, ApiService);
       }
       if(sectionFinded.format==='arrayRateConfig'){

        returnText =  await this.formatArrayRateConfig(parameter, ApiService);
    
       }
       if(sectionFinded.format==='arrayAggregatedValues'){

        returnText =  await this.formatAggregatedValues(parameter);
    
       }//
       if(sectionFinded.format==='arrayRateplanLink'){

        returnText =  await this.formatRateplanLink(parameter, ApiService);
    
       }
       
        return returnText;
    }

    async formatRateplanLink(valueFinded, ApiService){
        let returnString = '';
        valueFinded = valueFinded;
        let idx = 0;
        let variable =  await new Promise(async (resolve) =>  {                          
               await ApiService.rateplanService.getRateplanId(valueFinded.rateplan).then(response=>{                
                response = response.data.data.rateplan; 
                returnString+= response.name+' '+valueFinded.type+' '+valueFinded.value
               })
          
            resolve(returnString);            
        })       
         return variable
    }
    formatAggregatedValues(valueFinded){
        let returnString = '';
        let aggregates =[
            {
                name: 'Resort Credit',
                value: 'resort-credit'
            },
            {
                name: 'Spa Credit',
                value: 'spa-credit'
            },
            {
                name: 'Tour',
                value: 'tour'
            },
            {
                name: 'Golf',
                value: 'golf'
            },
            {
                name: 'Bottle of Wine',
                value: 'bottle-of-wine'
            },
            {
                name: 'Breakfast',
                value: 'breakfast'
            },
            {
                name: 'Dinner',
                value: 'dinner'
            },
            {
                name: 'Mini bar',
                value: 'mini-bar'
            },
            {
                name: 'Free Upgrade',
                value: 'free-upgrade'
            },
            {
                name: 'Wi-Fi',
                value: 'internet'
            },
            {
                name: 'Early Check In',
                value: 'early-check-in'
            },
            {
                name: 'Late Check Out',
                value: 'late-check-out'
            },
            {
                name: 'Vip Check In',
                value: 'vip-check-in'
            }

        ];
        valueFinded.forEach(element => {

            let infoIs = aggregates.find(item=>item.value === element.name)
            if(infoIs){
                returnString += infoIs.name+'</br>';
                returnString +='Español: '+ element.text_es+'</br>';
                returnString +='Inglés: '+ element.text_en+'</br>';
                returnString +=  '---------------------------------------------------------------------<br/>';                
                                   
            }
        });
       
        return returnString;
    }

    formatWeek(valueFinded){
        let returnString = '';
        let days = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'];
        valueFinded.forEach((element, idx) => {
            returnString +=  days[idx]+': '+element+'% </br>';
        });
        return returnString;
    }
    formatTransDay(valueFinded){
        let returnString = '';
        let days = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'];
        valueFinded.forEach((element, idx) => {

            
            returnString +=  days[element]+'</br>';
        });
        return returnString;
    }
    formatOptionsAdvance(valueFinded, keys, response){
        let returnString = '';
       
       
        let indxOf = keys.indexOf(valueFinded)
       
        
        if(indxOf>=0){
            returnString = response[indxOf];
        }
        return returnString;
    }
    formatArrayDates(valueFinded){
        let returnString = '';
        //console.log('valueFinded', valueFinded);
        let number_tot = valueFinded.length;
        
        valueFinded.forEach((element, idx) => {
            returnString += this.formatDate(element);
            if(number_tot>idx+1){
                returnString +=  ' | '
            }
            
        });
        
        return returnString;
    }
    async formatArrayRateConfig(valueFinded, ApiService){
        let returnString = '';
        let variable =  await new Promise(async (resolve) =>  {
        let backRoom = null;                            
        await this.asyncForEach(valueFinded, async element=>{
            //console.log('element', element);
            await ApiService.rateService.getRateConfigData({
                rate_config_id: element
            }).then(response=>{
                //console.log('response', response);
                response =  response.data.data.rateconfig; 
                if(backRoom!==response.room_data._id){
                    returnString +=  '<b>'+ response.room_data.name+'</b><br/>';   
                    backRoom = response.room_data._id;
                }    
                returnString +=  '=>  '+ response.rateplan_data.name+'<br/>'; 
                })
            
            })
            resolve(returnString);            
        })       
         return variable
      
        
    }
    async formatArrayRules(valueFinded, ApiService){
        let returnString = '';
        let variable =  await new Promise(async (resolve) =>  {
            let backRateplan = null;                            
        await this.asyncForEach(valueFinded, async element=>{
            await ApiService.rateService.getRateConfigData({
                rate_config_id: element.rate_config_id
            }).then(response=>{
                response =  response.data.data.rateconfig;  
                if(backRateplan!==response.rateplan_data._id){
                    returnString +=  '<b> Contrato: '+ response.rateplan_data.name+'</b><br/>'; 
                    backRateplan = response.rateplan_data._id;
                }
                returnString +=  'Habitación: '+ response.room_data.name+'<br/>';            
                element.conditions.forEach((condition, idx) => {
                    returnString +=  'Condición '+ (idx+1)+'<br/>';
                    returnString +=  'Ventas (CN): '+ condition.room_nights+ ' ('+ condition.rate_type+') <br/>';
                    returnString +=  'Tarifa: $'+ this.formatMoney(condition.price)+'<br/>';
                    }); 
                    returnString +=  '---------------------------------------------------------------------<br/>';                
                                        
                })
            
            })
            resolve(returnString);            
        })       
         return variable
      
        
    }
    formatArrayExceptions(valueFinded, option){
        let returnString = '';
        //console.log('valueFinded', valueFinded);
        if(option === 'exceptions'){
            valueFinded.forEach(element => {
                returnString += this.formatDate(element.from) + ' - '+ this.formatDate(element.to);
                returnString +=  ' | Periodo de cancelación '+ this.formatArrayPolitic(element.cancellation_time, 'time');
                returnString +=  ' | Dentro: '+ this.formatArrayPolitic(element.in_time_value, 'period');
                returnString +=  ' | Fuera: '+ this.formatArrayPolitic(element.out_time_value, 'period');
               
                returnString +=  '<br/>'; 
            });
        }
        if(option === 'no_refounds'){
            valueFinded.forEach(element => {
                returnString += this.formatDate(element.from) + ' - '+ this.formatDate(element.to);
                returnString +=  '<br/>'; 
            });
        }
        
        return returnString;
    }
    async formatArrayPermission(valueFinded, ApiService){
        let returnString = '';
        valueFinded = JSON.parse(valueFinded);
        let variable =  await new Promise(async (resolve) =>  {
            await this.asyncForEach(valueFinded, async element=>{                            
               await ApiService.propertyService.getPropertyId(element.property).then(response=>{
                    response =  response.data.data.property;            
                    returnString +=  'Propiedad: '+ response.name+'<br/>';                      
                        element.sections.forEach((item, idx) => {
                            if(item>0){
                                returnString +=  this.rolesNames(idx)+': ' + this.rolesCheck(item)+'<br/>';
                            }                            
                    })
                    returnString +=  '---------------------------------------------------------------------<br/>'; 
               })
            })
            resolve(returnString);            
        })       
         return variable
    }
    async formatArrayRateplan(valueFinded, ApiService){
        let returnString = '';
        valueFinded = valueFinded;
        let idx = 0;
        let variable =  await new Promise(async (resolve) =>  {
            await this.asyncForEach(valueFinded, async element=>{                            
               await ApiService.rateplanService.getRateplanId(element).then(response=>{                
                response = response.data.data.rateplan;          
                returnString += response.name+'<br/>';   
               })
            })
            resolve(returnString);            
        })       
         return variable
    }
    formatStateReservation(valueFinded){
        let states =[
           
            {
                name: 'Realizada',
                value: 1
            },
            {
                name: 'Confirmada',
                value: 2
            },
            {
                name: 'Cancelada',
                value: 3
            }
        ];
        return states.find(item => item.value === valueFinded).name;
    }
    formatArrayPolitic(element, option){
        let valueReturn = '';
        if(option==='period' && element!==null){
            valueReturn = period_types.find(item=> item.value===element).name
        }
        if(option==='time' && element!==null){
            valueReturn = cancellation_times.find(item=> item.value===element).name
        }
       
        return valueReturn;
    }
    formatRefundable(booleanValue){
        let returnString = 'No Reembolsable';
        if(booleanValue){
            returnString = 'Reembolsable';
        }
        return returnString;
    }
    formatBoolean(booleanValue){
        let returnString = 'Inactivo';
        if(booleanValue){
            returnString = 'Activo';
        }
        return returnString;
    }
    formatSalePolitic(valueFinded){
        let price_types =[
            {
                _id: 1,
                name: 'Por reservación'
            },
            {
                _id: 2,
                name: 'Por persona'
            },
            {
                _id: 3,
                name: 'Por habitación'
            },
            {
                _id:4,
                name: 'Por persona por noche'
            },
            {
                _id:5,
                name: 'Por habitación por noche'
            }
        ];
        return price_types.find(item => item._id === valueFinded).name;
    }
    formatArray(valueFinded, keysResponse){
        let returnString = '';
        //console.log('valueFinded', valueFinded);
        valueFinded = JSON.parse(valueFinded);
        valueFinded.forEach(element => {
            keysResponse.forEach((key, idx) => {
                let itemFinded = labelNames.find(item => item.name === key);
                if(itemFinded){
                    returnString += itemFinded.text +': '+ element[key] + (idx+1< keysResponse.length?', ': '')
                }else{
                    returnString += element[key] + ' '
                }
            });
            returnString +=  '<br/>'; 
        });
        return returnString;
    }
    formatObject(element, keysResponse){
        let returnString = '';
            keysResponse.forEach((key, idx) => {
                /*let itemFinded = labelNames.find(item => item.name === key);
                if(itemFinded){
                    returnString += itemFinded.text +': '+ element[key] + (idx+1< keysResponse.length?', ': '')
                }else{*/
                    returnString += element[key] + ' '
                //}
            });
       
        return returnString;
    }
    formatArrayTrans(valueFinded){
        let returnString = '';
        /*console.log('valueFinded', valueFinded);
        valueFinded = JSON.parse(valueFinded);*/
        valueFinded.forEach((element, idx) => {

            let itemFinded = optionsNames.find(item => item.name === element);
            if(itemFinded){
                returnString += itemFinded.text + (idx+1< valueFinded.length?', ': '')
            }else{
                returnString += element + (idx+1< valueFinded.length?', ': '')
            }
           
        });
        return returnString;
    }

    formatDate(date){
        let valueReturn = moment(date, 'YYYY-MM-DD HH:mm').locale('es').format('DD-MMM-YYYY').toUpperCase();
        return valueReturn;
    }

    formatLang(valueFinded){

        //console.log('valueFinded', valueFinded);
        let returnString = '';
        valueFinded = JSON.parse(valueFinded);
        valueFinded.forEach(element => {
            returnString += '['+element.lang.toUpperCase()+'] '+element.text + '<br/>'; 
        });

        return returnString;
    }
    formatPolitic(value, type){

    }

    formatMoney(value){
        value = Number(value)

        return this.numberWithCommas(value.toFixed(2));
    }

    findSection(valueFinded){
        return sections.find(item => item.name === valueFinded);
    }
    getSections(){
        return  sections;
    }
    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    cheackProp(dataOriginal, dataActual, section){
        let responseChanges = [];
        section = this.findSection(section)

        let compareInArray = section.arrayValidate
        let ignoreInArray = section.arrayIgnore
        let removeInArray = section.removeInArray
        let removeInArrayPlus = section.removeInArrayPlus

        console.log('compareInArray');
        console.log(dataOriginal);
        console.log(dataActual);
        for (let prop in dataOriginal) {
            if(compareInArray.indexOf(prop) >=0 ){

                if(this.checkArray(dataOriginal[prop], dataActual[prop]) === false){
                    responseChanges.push({
                        parameter: prop,
                        old:dataOriginal[prop],
                        new:dataActual[prop],
                    })
                }
            }
            else if(removeInArray.length && removeInArray.indexOf(prop) >=0 ){
                dataOriginal[prop] = this.removeParam(dataOriginal[prop]);
                dataActual[prop] = this.removeParam(dataActual[prop]);

                //console.log('responseChanges',  dataOriginal[prop],   dataActual[prop]);
                if((dataOriginal[prop] !== dataActual[prop])){
                    responseChanges.push({
                        parameter: prop,
                        old:dataOriginal[prop],
                        new:dataActual[prop],
                    })
                }
            } else if(removeInArrayPlus.length && removeInArrayPlus.indexOf(prop) >=0 ){
                dataOriginal[prop] = this.removeParam(dataOriginal[prop], true);
                dataActual[prop] = this.removeParam(dataActual[prop], true);

                //console.log('responseChanges',  dataOriginal[prop],   dataActual[prop]);
                if((dataOriginal[prop] !== dataActual[prop])){
                    responseChanges.push({
                        parameter: prop,
                        old:JSON.parse(dataOriginal[prop]),
                        new:JSON.parse(dataActual[prop]),
                    })
                }
            }
            else if(ignoreInArray.indexOf(prop) >=0 ){

            }else{
                if(dataOriginal[prop] !== dataActual[prop]){
                    responseChanges.push({
                        parameter: prop,
                        old:dataOriginal[prop],
                        new:dataActual[prop],
                    })
                }
            }
        }
        console.log('responseChanges', responseChanges);
        return responseChanges;
    }
    checkArray(a = [], b=[]) {
        if (a.length !== b.length) return false;
        const uniqueValues = new Set([...a, ...b]);
        for (const v of uniqueValues) {
            const aCount = a.filter(e => e === v).length;
            const bCount = b.filter(e => e === v).length;
            if (aCount !== bCount) return false;
        }
        return true;
    }
    removeParam(arrayItems, plus=false){
        arrayItems.map( item => delete item.updatedAt);
        if(plus){
            arrayItems.map( item => delete item._id);
            arrayItems.map( item => delete item.createdAt);
            arrayItems.map( item => delete item.updatedAt);

        }
        return JSON.stringify(arrayItems);
    }
    createSlug(str){       
            const cleanedStr = removeAccents(str)
            .toLowerCase()
            .replace(/&/g, 'y') // Reemplazar & por y
            .replace(/[^-\w]+/g, '-') // Reemplazar caracteres no alfanuméricos por -
            .replace(/-+/g, '-') // Eliminar múltiples guiones consecutivos
            .replace(/^-|-$|/g, ''); // Eliminar guiones al principio y al final          
          return cleanedStr;       
          

    }
    rolesNames(idx){
       let  name = [
            'Habitaciones y tarifas',
            'Reservaciones',
            'Marketing',
            'Revenue',
            'Contabilidad',
            'Propiedad',
        ];

        return name[idx];
    }
    rolesCheck(value){
        let  stringValue = [
            '',
            'Lectura',
            'Lectura y Escritura'
        ];

        return stringValue[value]; 
    }

    async asyncForEach (array, callback) {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }
    /*async processApi(ApiService, params){

        return new Promise(async (resolve) =>  {
            let response =  await 
       
            resolve(response);
            
        })

    }*/

}

