const labelNames = [
    
    {
        name:'rate_config_rules',
        text: 'Condiciones'
    },

    {
        name:'dates',
        text: 'Fechas'
    },

    {
        name:'confirmed_at',
        text: 'Hora de confirmación'
    },

    {
        name:'cancelled_at',
        text: 'Hora de cancelación'
    },

    {
        name:'price_children',
        text: 'Por niño'
    },

    {
        name:'price_jr',
        text: 'Precios Junior'
    },
    {
        name:'price_adult',
        text: 'Precios Adultos'
    },
    {
        name:'price_reservation',
        text: 'Por reservación'
    },
    {
        name:'price_room',
        text: 'Por habitación'
    },
    {
        name:'qty',
        text: 'Inventario'
    },
    {
        name:'price_type',
        text: 'Política de venta'
    },
    {
        name:'nights',
        text: 'Mínimo Noches'
    },
    {
        name:'minimum_nights',
        text: 'Aplica mínimo Noches'
    },
    {
        name:'arrive',
        text: 'Disponible el día de la llegada'
    },
    {
        name:'departure',
        text: 'Disponible el día de la salida'
    },
    {
        name:'service',
        text: 'Servicio general'
    },
    {
        name:'get_service',
        text: 'Solicitud del servicio'
    },
    {
        name:'password',
        text: 'Contraseña'
    },
    {
        name:'password',
        text: 'Contraseña'
    },
    {
        name:'email',
        text: 'Correo electrónico'
    },
    {
        name:'is_admin',
        text: 'Permiso de administrador'
    },
    {
        name:'first_name',
        text: 'Nombre'
    },
    {
        name:'cellphone',
        text: 'Número de telefono móvil'
    },
    {
        name:'lada_cell',
        text: 'Prefijo telefónico nacional para móviles'
    },
    {
        name:'lada',
        text: 'Prefijo telefónico nacional fijo'
    },
    {
        name:'last_name',
        text: 'Apellido(s)'
    },
    {
        name: 'name',
        text: 'Nombre'
    },
    {
        name: 'date',
        text: 'Fecha'
    },
    {
        name: 'cod',
        text: 'Código',
    },
    {
        name: 'vigency',
        text: 'Vigencia',
    },
    {
        name: 'meal_plan',
        text: 'Tipo de plan'
    },
    {
        name: 'is_public',
        text: 'Abierto a público',
    },
    {
        name: 'currency',
        text: 'Moneda base',
    },
    {
        name: 'order',
        text: 'Orden',
    },
    {
        name: 'description',
        text: 'Descripción',
    },

    {
        name: 'code',
        text: 'Código'
    },
    {
        name: 'phone',
        text: 'Teléfono'
    },
    {
        name: 'razon',
        text: 'Razón social'
    },
    {
        name: 'brand',
        text: 'Marca '
    },
    {
        name: 'url',
        text: 'Sitio web'
    },
    {
        name: 'phone',
        text: 'Teléfono'
    },
    {
        name: 'payment_policy',
        text: 'Políticas de pago'
    },
    {
        name: 'terms',
        text: 'Términos y condiciones'
    },
    {
        name: 'privacy',
        text: 'Políticas de privacidad del hotel'
    },
    {
        name: 'contact_name',
        text: 'Nombre para contacto'
    },
    {
        name: 'contact_phone',
        text: 'Teléfono para contacto'
    },
    {
        name: 'contact_email',
        text: 'Correo electrónico para contacto'
    },
    {
        name: 'currency_exchange',
        text: 'Tipo de cambio'
    },
    {
        name: 'time_arrive',
        text: 'Horario de llegada'
    },
    {
        name: 'time_departure',
        text: 'Horario de salida'
    },
    {
        name: 'color_header',
        text: 'Color principal (botones)'
    },
    {
        name: 'color_second',
        text: 'Color secundario (textos)'
    },
    {
        name: 'color_main',
        text: 'Color de fondo'
    },
    {
        name: 'gtm_id',
        text: 'ID de contenedor Google Tag Manager'
    },
    {
        name: 'mail_bcc',
        text: 'Listado de destinatarios ocultos'
    },
    {
        name: 'mail_cc',
        text: 'Listado de destinatarios visibles'
    },
    {
        name: 'age_infants',
        text: 'Edad máximo Infantes'
    },
    {
        name: 'age_child',
        text: 'Edad máximo Niños'
    },
    {
        name: 'age_jr',
        text: 'Edad máximo Juniors'
    },
    {
        name: 'only_adults',
        text: 'Solo para Adultos'
    },
    {
        name: 'category',
        text: 'Categoría'
    },
    {
        name: 'country',
        text: 'Pais'
    },
    {
        name: 'state',
        text: 'Estado'
    },
    {
        name: 'city',
        text: 'Ciudad'
    },
    {
        name: 'street',
        text: 'Calle'
    },
    {
        name: 'int_number',
        text: 'Número interior'
    },
    {
        name: 'ext_number',
        text: 'Número exterior'
    },
    {
        name: 'suburb',
        text: 'Colonia'
    },
    {
        name: 'zipcode',
        text: 'Código postal'
    },
    {
        name: 'b_country',
        text: 'Pais'
    },
    {
        name: 'b_city',
        text: 'Ciudad'
    },
    {
        name: 'b_state',
        text: 'Estado'
    },
    {
        name: 'b_country',
        text: 'Ciudad'
    },
    {
        name: 'b_street',
        text: 'Calle'
    },
    {
        name: 'b_int_no',
        text: 'Número interior'
    },
    {
        name: 'b_ext_no',
        text: 'Número exterior'
    },
    {
        name: 'b_suburb',
        text: 'Colonia'
    },
    {
        name: 'b_zp',
        text: 'Código postal'
    },
    {
        name: 'booking_url',
        text: 'URL Booking'
    },
    {
        name: 'included_taxes',
        text: 'Tarifas cargadas con impuestos'
    },
    {
        name: 'iva',
        text: 'IMPUESTO DEL VALOR AGREGADO (IVA)'
    },
    {
        name: 'ish',
        text: 'IMPUESTO SOBRE HOSPEDAJE(IHG)'
    },
    {
        name: 'plan_ep',
        text: 'Plan Europeo'
    },
    {
        name: 'plan_epb',
        text: 'Plan Europeo + Desayuno'
    },
    {
        name: 'plan_ai',
        text: 'Todo Incluido'
    },
    {
        name: 'pay_tdc',
        text: 'Pasarela de pago (OPENPAY)'
    },
    {
        name: 'tdc_key',
        text: 'Cliente ID (OPENPAY)'
    },
    {
        name: 'tdc_secret',
        text: 'Llave Privada (OPENPAY)'
    },
    {
        name: 'tdc_public',
        text: 'Llave Pública (OPENPAY)'
    },
    {
        name: 'pay_paypal',
        text: 'Pasarela de pago (PAYPAL)'
    },
    {
        name: 'paypal_key',
        text: 'Cliente ID (PAYPAL)'
    },
    {
        name: 'paypal_secret',
        text: 'Llave Secreta (PAYPAL)'
    },
    {
        name: 'pay_hotel',
        text: 'Pago en el hotel (verificación de tarjeta)'
    },
    {
        name: 'pay_validate',
        text: 'Verificación tarjeta de crédito / débito'
    },
    {
        name: 'pay_firstdata',
        text: 'Pasarela de pago (FIRSTDATA)'
    },
    {
        name: 'firstdata_key',
        text: 'Llave API (FIRSTDATA)'
    },
    {
        name: 'firstdata_secret',
        text: 'Llave Secreta (FIRSTDATA)'
    },
    {
        name: 'pay_stripe',
        text: 'Pasarela de pago (STRIPE)'
    },
    {
        name: 'stripe_secret',
        text: 'Llave Secreta (STRIPE)'
    },
    {
        name: 'stripe_comision',
        text: 'Comisión STRIPE'
    },
    {
        name: 'stripe_cuenta_id',
        text: 'Cliente ID (STRIPE)'
    },
    {
        name: 'rfc',
        text: 'RFC'
    },
    {
        name: 'business_name',
        text: 'RAZÓN SOCIAL'
    },
    {
        name: 'b_cfdi_usage',
        text: 'USO DE CDFI'
    },
    {
        name: 'payment_options',
        text: 'Tipo de pago'
    },
    {
        name: 'cancellation_policy',
        text: 'Políticas de cancelación'
    },
    {
        name: 'rates_list',
        text: 'Tarifas'
    },
    {
        name: 'from',
        text: 'Vigencia inicio'
    },
    {
        name: 'to',
        text: 'Vigencia fin'
    },
    {
        name: 'test',
        text: 'test'
    },
    {
        name: 'rateplan_link',
        text: 'Vinculo de contrato'
    },
    {
        name: 'view',
        text: 'Vista'
    },
    {
        name: 'type',
        text: 'Tipo'
    },
    {
        name: 'area',
        text: 'Superficie'
    },
    {
        name: 'no_rooms',
        text: 'No. de recamaras'
    },
    {
        name: 'no_baths',
        text: 'No. de baños'
    },
    {
        name: 'max_pax',
        text: 'Capacidad máxima'
    },
    {
        name: 'max_adults',
        text: 'Máximo de adultos'
    },
    {
        name: 'max_children',
        text: 'Máximo de niños'
    },
    {
        name: 'max_jr',
        text: 'Máximo de Juniors'
    },
    {
        name: 'amenities',
        text: 'Amenidades'
    },
    {
        name: 'agregate_values',
        text: 'Valores agregados'
    },
    {
        name: 'slider',
        text: 'Imagenes'
    },
    {
        name: 'images',
        text: 'Imagenes de la habitación'
    },
    {
        name: 'refundable',
        text: 'Esta tarifa no es reembolsable'
    },
    {
        name: 'cancellation_time',
        text: 'Periodo de cancelación'
    },
    {
        name: 'in_time_type',
        text: 'Dentro del periodo de penalización'
    },
    {
        name: 'in_time_value',
        text: 'Dentro del periodo de penalización valor'
    },
    {
        name: 'out_time_type',
        text: 'Fuera del periodo de penalización'
    },
    {
        name: 'out_time_value',
        text: 'Fuera del periodo de penalización valor'
    },
    {
        name: 'refundable_dates',
        text: 'Fechas no reembolsables'
    },
    {
        name: 'season_exception_dates',
        text: 'Excepciones de temporada'
    },
    {
        name: 'main',
        text: 'Establecer política de cancelación por defecto'
    },
    {
        name: 'status',
        text: 'Estado'
    },
    {
        name: 'inventory',
        text: 'Inventario'
    },
    {
        name: 'cutoff',
        text: 'Fecha límite reservación'
    },
    {
        name: 'closed_dates',
        text: 'Fecha cerrada'
    },

    {
        name: 'slug',
        text: 'Slug'
    },
    {
        name: 'rateplans',
        text: 'Contratos'
    },
    {
        name: 'title_banner',
        text: 'Título principal en imagen'
    },
    {
        name: 'text_banner',
        text: 'Título secundario en imagen'
    },
    {
        name: 'title_content',
        text: 'Título contenido'
    },
    {
        name: 'content',
        text: 'Contenido principal'
    },
    {
        name: 'title_gratitude',
        text: 'Título Agradecimientos'
    },
    {
        name: 'gratitude',
        text: 'Agradecimientos'
    },
    {
        name: 'image',
        text: 'Imagen principal'
    },
    {
        name: 'file',
        text: 'Adjuntar archivo'
    },
    {
        name: 'room_configs',
        text: 'Arreglo'
    },
    {
        name: 'single',
        text: 'Individual'
    },
    {
        name: 'double',
        text: 'Matrimonial'
    },
    {
        name: 'king',
        text: 'King Size'
    },
    {
        name: 'queen',
        text: 'Queen Size'
    },
    {
        name: 'couch',
        text: 'Sofa cama'
    },
    {
        name: 'bunk',
        text: 'Litera'
    },
    {
        name: 'linked_rateplans',
        text: 'Contratos vinculados'
    }
]

const optionsNames = [
    {
        name: 'TDD',
        text: 'Tarjeta de crédito/debito (OPENPAY)'
    },
    {
        name: 'PAYPAL',
        text: 'PayPal'
    },
    {
        name: 'ARRIVE',
        text: 'Pago en destino',
    },
    {
        name: 'VALIDATECARD',
        text: 'Verificación de tarjeta',
    },
    {
        name: 'FIRSTDATA',
        text: 'Tarjeta de crédito/debito (FIRSTDATA)'
    },
    {
        name: 'STRIPE',
        text: 'Tarjeta de crédito/debito (STRIPE)',
    }
]
const cancellation_times = [
    {
        name: '2 horas',
        value: 2,
    },
    {
        name: '6 horas',
        value: 6,
    },
    {
        name: '8 horas',
        value: 8,
    },
    {
        name: '24 horas',
        value: 24,
    },
    {
        name: '48 horas',
        value: 48,
    },
    {
        name: '72 horas',
        value: 72,
    },
    {
        name: '4 días (96 horas)',
        value: 96
    },
    {
        name: '5 días (120 horas)',
        value: 120
    },
    {
        name: '6 días (144 horas)',
        value: 144
    },
    {
        name: '1 semana (168 horas)',
        value:168
    },
    {
        name: '2 semanas (336 horas)',
        value: 336
    },
    {
        name: '3 semanas (504 horas)',
        value: 504
    },
    {
        name: '4 semanas (672 horas)',
        value: 672
    },
    {
        name: '1 mes (720 horas)',
        value: 720
    },
    {
        name: '6 semanas (1008 horas)',
        value: 1008
    },
    {
        name: '45 días (1080 horas)',
        value: 1080
    },
    {
        name: '7 semanas (1176 horas)',
        value: 1176
    },
    {
        name: '8 semanas (1344 horas)',
        value: 1344
    },
    {
        name: '60 días (1440 horas)',
        value: 1440
    },
    {
        name: '90 días (2160 horas)',
        value: 2160
    }
]
const period_types = [
    {
        _id: 1,
        type: 0,
        name: 'Nada',
        value: 0
    },
    {
        _id: 2,
        type: 1,
        name: 'Tasa fija',
        value: 500
    },
    {
        _id: 3,
        type: 2,
        name: 'Primera noche + impuestos',
        value: 1
    },
    {
        _id: 4,
        type: 2,
        name: '2 noches + impuestos',
        value: 2
    },
    {
        _id: 5,
        type: 3,
        name: '10% de la estancia + tasas',
        value: 10
    },
    {
        _id: 6,
        type: 3,
        name: '20% de la estancia + tasas',
        value: 20
    },
    {
        _id: 7,
        type: 3,
        name: '30% de la estancia + tasas',
        value: 30
    },
    {
        _id: 8,
        type: 3,
        name: '40% de la estancia + tasas',
        value: 40
    },
    {
        _id: 9,
        type: 3,
        name: '50% de la estancia + tasas',
        value: 50
    },
    {
        _id: 10,
        type: 3,
        name: '60% de la estancia + tasas',
        value: 60
    },
    {
        _id: 11,
        type: 3,
        name: '70% de la estancia + tasas',
        value: 70
    },
    {
        _id: 12,
        type: 3,
        name: '80% de la estancia + tasas',
        value: 80,
    },
    {
        _id: 13,
        type: 3,
        name: '90% de la estancia + tasas',
        value: 90
    },
    {
        _id: 14,
        type: 3,
        name: '100% de la estancia + tasas',
        value: 100
    }
]
export default class  {}