
export const landingTranslate = {




    es: {

        index: {
            home: 'Inicio',
            title : 'Landings',
            new: 'Crear nuevo landing',
            name: 'Nombre',
            vigency: 'Vigencia',
            room_nigth: 'Cuarto Noche',
            ingrese: 'Ingresos (MXN)',
            dias_restantes: 'Días restantes',
            actions: 'Acciones',
            pause: 'Pausar',
            has: 'Tiene',
            a_has_plural: 'landings activos',
            a_has_singular: 'landing activo',
            c_has_plural: 'landings caducados',
            c_has_singular: 'landing caducado',
            i_has_plural: 'landings inactivos',
            i_has_singular: 'landing inactivo',
            search: 'Buscar landings',

        },
        filter:{
            vigencies: 'Vigentes',
            caducated: 'Vencidas',
            futures: 'Futuras',
            on_pause: 'En pausa',
            inactives: 'Inactivas',
        },
        new:{
            title : 'Crear nuevo landing',
            header : 'Crear nuevo landing',
            modal: '¡Operación exitosa!',
            button:  'Crear landing',
            msg: 'Landing guardado'
        },
        edit: {
            header : 'Editar landing',
            button:  'Actualizar landing',
            msg: 'Landing actualizado'

        },
        form:{
            image: 'Imagen principal',
            image_movil: 'Imagen principal móvil',
            title_banner: 'Título principal en imagen',
            text_banner: 'Título secundario en imagen',
            text_color: 'Color del texto',
            title_content: 'Título contenido',
            content: 'Contenido principal',
            gratitude: 'Agradecimientos',
            title_gratitude: 'Título Agradecimientos',
            vigency: 'Vigencia',
            file: 'Adjuntar archivo',
            name: 'Nombre',
            apply: 'Aplicar a',
            apply_legend: 'Todas las habitaciones y tarifas',

        },
        button:{
            next :'Siguiente',
            edit :'Editar',
            save: 'Crear landing',
            cancel: 'Cancelar'
        }

    },

    en: {
    },
};