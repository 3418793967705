

const singleName = 'servicio extra';
const pluralName = 'servicios extra';
const singleNameMay = 'Servicio extra';
const pluralNameMay = 'Servicios extra';





export const addonTranslate = {
    es: {


        index: {
            home: 'Inicio',
            title : pluralNameMay,
            new: 'Crear nuevo '+singleName,
            name: 'Nombre servicio',
            service: 'Servicio general',
            get_service: 'Solicitud del servicio',
            package: 'Paquete',
            order: 'Orden',
            room_nigth: 'Cuarto Noche',
            ingrese: 'Ingresos (MXN)',
            ingrese_usd: 'Ingresos (USD)',
            dias_restantes: 'Días restantes',
            actions: 'Acciones',
            pause: 'Pausar',
            has: 'Tiene',
            a_has_plural: pluralName+' activos',
            a_has_singular: singleName+' activo',
            c_has_plural: pluralName+' caducados',
            c_has_singular: singleName+' caducado',
            i_has_plural: pluralName+' inactivos',
            i_has_singular: singleName+' inactivo',
            search: 'Buscar '+pluralName,

        },
        filter:{
            vigencies: 'Vigentes',
            caducated: 'Vencidas',
            futures: 'Futuras',
            on_pause: 'En pausa',
            inactives: 'Inactivas',
        },
        new:{
            title : 'Crear nuevo '+singleName,
            header : 'Crear nuevo '+singleName,
            modal: '¡Operación exitosa!',
            button:  'Crear '+singleName,
            msg: singleNameMay+' guardado'
        },
        edit: {
            header : 'Editar '+singleName,
            button:  'Actualizar '+singleName,
            msg: singleNameMay+' actualizado'

        },

        form:{
            image: 'Imagen principal',
            name: 'Nombre servicio',
            service: 'Servicio general',
            get_service: 'Solicitud del servicio',
            package: 'Paquete',
            option_package: 'Opción de Paquete',
            minimum_nights: 'Aplica mínimo Noches',
            nights: 'Mínimo Noches',
            departure: 'Disponible el día de la salida',
            arrive: 'Disponible el día de la llegada',
            description: 'Descripción',
            detail_price: 'Detalle del precio',
            title_enchanced: 'Servicios agregados',
            sales_policy: 'Política de venta',
            content: 'Contenido principal',
            gratitude: 'Agradecimientos',
            title_gratitude: 'Título Agradecimientos',
            vigency: 'Vigencia',
            file: 'Adjuntar archivo',
            apply: 'Aplicar a',
            apply_legend: 'Todas las habitaciones y tarifas',
            qty: 'Inventario'

        },
        button:{
            next :'Siguiente',
            edit :'Editar',
            save: 'Crear '+singleName,
            cancel: 'Cancelar'
        }

    },

    en: {
    },
};