import DashboardLayout from 'src/pages/Dashboard/Layout/DashboardLayout.vue'
// GeneralViews
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue'
// Dashboard pages
import Overview from 'src/pages/Dashboard/Dashboard/Overview.vue'
import Stats from 'src/pages/Dashboard/Dashboard/Stats.vue'

// Pages
import User from 'src/pages/Dashboard/Pages/UserProfile.vue'
import TimeLine from 'src/pages/Dashboard/Pages/TimeLinePage.vue'
import Login from 'src/pages/Dashboard/Pages/Login.vue'
import Register from 'src/pages/Dashboard/Pages/Register.vue'
import Lock from 'src/pages/Dashboard/Pages/Lock.vue'

// Components pages
import Buttons from 'src/pages/Dashboard/Components/Buttons.vue'
import GridSystem from 'src/pages/Dashboard/Components/GridSystem.vue'
import Panels from 'src/pages/Dashboard/Components/Panels.vue'

const SweetAlert = () => import('src/pages/Dashboard/Components/SweetAlert.vue')
import Notifications from 'src/pages/Dashboard/Components/Notifications.vue'
import Icons from 'src/pages/Dashboard/Components/Icons.vue'
import Typography from 'src/pages/Dashboard/Components/Typography.vue'

// Forms pages
const RegularForms = () => import('src/pages/Dashboard/Forms/RegularForms.vue')
const ExtendedForms = () => import('src/pages/Dashboard/Forms/ExtendedForms.vue')
const ValidationForms = () => import('src/pages/Dashboard/Forms/ValidationForms.vue')
const Wizard = () => import('src/pages/Dashboard/Forms/Wizard.vue')

// TableList pages
const RegularTables = () => import('src/pages/Dashboard/Tables/RegularTables.vue')
const ExtendedTables = () => import('src/pages/Dashboard/Tables/ExtendedTables.vue')
const PaginatedTables = () => import('src/pages/Dashboard/Tables/PaginatedTables.vue')
// Maps pages
const GoogleMaps = () => import('src/pages/Dashboard/Maps/GoogleMaps.vue')
const FullScreenMap = () => import('src/pages/Dashboard/Maps/FullScreenMap.vue')
const VectorMaps = () => import('src/pages/Dashboard/Maps/VectorMapsPage.vue')

// Calendar
const Calendar = () => import('src/pages/Dashboard/Calendar/CalendarRoute.vue')
// Charts
const Charts = () => import('src/pages/Dashboard/Charts.vue')

const Users = () => import('src/pages/Project/users/index.vue')
const CreateUser = () => import('src/pages/Project/users/create.vue')

const Properties = () => import('src/pages/Project/properties/index.vue')
const CreateProperty = () => import('src/pages/Project/properties/create.vue')
const Property = () => import('src/pages/Project/properties/index-one.vue')
const DataProperty = () => import('src/pages/Project/properties/data.vue')
const ResumeProperty = () => import('src/pages/Project/properties/resume.vue')


const Rooms = () => import('src/pages/Project/rooms/index.vue')
const CreateRoom = () => import('src/pages/Project/rooms/create.vue')
const Test = () => import('src/pages/Project/ZTest/test.vue')


const Contracts = () => import('src/pages/Project/contracts/index.vue')
const CreateContract = () => import('src/pages/Project/contracts/create.vue')
const CreateContractNew = () => import('src/pages/Project/contracts/create_new.vue')
const CreateContractOld = () => import('src/pages/Project/contracts/create_respaldo.vue')

const Annulments = () => import('src/pages/Project/annulments/index.vue')
const CreateAnnulments = () => import('src/pages/Project/annulments/create.vue')

const indexTerms = () => import('src/pages/Project/properties/terms.vue')
const editTerms = () => import('src/pages/Project/properties/terms-create.vue')
const configurationProperty = ()  =>  import('src/pages/Project/properties/configuration.vue')


const Rates = () => import('src/pages/Project/rates/index.vue')
const CreateRate = () => import('src/pages/Project/rates/create.vue')
const FormInventory = () => import('src/pages/Project/rates/form-inventary.vue')
const FormRestricts = () => import('src/pages/Project/rates/form-restricts.vue')
const FormRestrictsNew = () => import('src/pages/Project/rates/form-restricts-new.vue')
const Reservations = () => import('src/pages/Project/reservations/index.vue')
const CreateReservation = () => import('src/pages/Project/reservations/create.vue')

const Promotions = () => import('src/pages/Project/promotions/index.vue')


const CreatePromotion = () => import('src/pages/Project/promotions/create_update.vue')
//const CreatePromotion = () => import('src/pages/Project/promotions/create.vue')
const CreatePromotionBack = () => import('src/pages/Project/promotions/create_back.vue')

const Revenues = () => import('src/pages/Project/revenue/index.vue')
const CreateRevenue = () => import('src/pages/Project/revenue/create.vue')

const Landings = () => import('src/pages/Project/landings/index.vue')
const CreateLanding = () => import('src/pages/Project/landings/create.vue')


const Addons = () => import('src/pages/Project/addons/index.vue')
const CreateAddon = () => import('src/pages/Project/addons/create.vue')


const IntegrationRatetiger = () => import('src/pages/Project/integrations/ratetiger.vue')

const Logs = () => import('src/pages/Project/logs/index.vue')




import Vue from "vue";
import VueRouter from 'vue-router'
Vue.use(VueRouter);

let loginPage = {
    path: '/login',
    name: 'Login',
    component: Login
}

let registerPage = {
    path: '/register',
    name: 'Register',
    component: Register
}

let lockPage = {
    path: '/lock',
    name: 'Lock',
    component: Lock
}
// TODO: Poner permisos a las urls para que no vayan a entrar poníendola en el navegador
const routes = [
    {
        path: '/',
        redirect: '/login',
        name: 'login',
    },
    /*componentsMenu,
    formsMenu,
    tablesMenu,
    mapsMenu,
    pagesMenu,*/
    loginPage,
    registerPage,
    lockPage,
    {
        path: '/admin',
        component: DashboardLayout,
        redirect: '/admin/properties',
        children: [
            /*{
                path: 'stats',
                name: 'Stats',
                component: Stats
            },
            {
                path: 'calendar',
                name: 'Calendar',
                component: Calendar
            },
            {
                path: 'charts',
                name: 'Charts',
                component: Charts
            },*/

            {
                path: 'test',
                name: 'Test',
                component: Test,
            },

            /*------------------------------------------------------*/
            /*--------------- ROUTE PARA CRUD USERS ----------------*/
            /*------------------------------------------------------*/

            {
                path: 'users',
                name: 'Users',
                component: Users,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: 'users/create',
                name: 'createUser',
                component: CreateUser,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: 'users/edit/:id',
                name: 'editUser',
                component: CreateUser,
                meta: {
                    requireAuth: true
                }
            },

            /*------------------------------------------------------*/
            /*--------------- ROUTE PARA CRUD RATEPLANS ------------*/
            /*------------------------------------------------------*/

            {
                path: 'contracts',
                name: 'Contracts',
                component: Contracts,
                meta: {
                    requireAuth: true
                }
            },

            {
                path: 'contracts/create',
                name: 'createContract',
                //component: CreateContractOld,
                component: CreateContractNew,
                meta: {
                    requireAuth: true
                }
            },

            {
                path: 'contracts/edit/:id',
                name: 'editContract',
                //component: CreateContractOld,
                component: CreateContractNew,
                meta: {
                    requireAuth: true
                }
            },





            {
                path: 'rates/rates-availability',
                name: 'Rates',
                component: Rates,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: 'rates/rates-availability/date/:date',
                name: 'RatesDates',
                component: Rates,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: 'rates/inventory-availability',
                name: 'FormInventory',
                component: FormInventory,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: 'rates/rates-restricts',
                name: 'FormRestricts',
               // component: FormRestricts,
                component: FormRestrictsNew,
                meta: {
                    requireAuth: true
                }
            },

            /*------------------------------------------------------*/
            /*--------------- ROUTE PARA CRUD RESERVATIONS ---------*/
            /*------------------------------------------------------*/

            {
                path: 'reservations',
                name: 'Reservations',
                component: Reservations,
                meta: {
                    requireAuth: true
                }
            },

            {
                path: 'reservations/reservation-show/:id',
                name: 'ViewReservation',
                component: CreateReservation,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: 'reservations/reservation/:id',
                name: 'EditReservation',
                component: CreateReservation,
                meta: {
                    requireAuth: true
                }
            },

            /*------------------------------------------------------*/
            /*--------------- ROUTE PARA CRUD PROMOTIONS -----------*/
            /*------------------------------------------------------*/

            {
                path: 'promotions',
                name: 'Promotions',
                component: Promotions,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: 'promotion/edit/:id',
                name: 'editPromotion',
                component: CreatePromotion,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: 'promotion/create',
                name: 'CreatePromotion',
                component: CreatePromotion,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: 'promotions/create_back',
                name: 'CreatePromotionBack',
                component: CreatePromotionBack,
                meta: {
                    requireAuth: true
                }
            },

            /*------------------------------------------------------*/
            /*--------------- ROUTE PARA CRUD REVENUES -------------*/
            /*------------------------------------------------------*/

            {
                path: 'revenues',
                name: 'Revenues',
                component: Revenues,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: 'revenue/edit/:id',
                name: 'editRevenue',
                component: CreateRevenue,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: 'revenue/create',
                name: 'CreateRevenue',
                component: CreateRevenue,
                meta: {
                    requireAuth: true
                }
            },

            /*------------------------------------------------------*/
            /*--------------- ROUTE PARA CRUD LANDINGS -------------*/
            /*------------------------------------------------------*/

            {
                path: 'landings',
                name: 'Landings',
                component: Landings,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: 'landing/create',
                name: 'CreateLanding',
                component: CreateLanding,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: 'landing/edit/:id',
                name: 'editLanding',
                component: CreateLanding,
                meta: {
                    requireAuth: true
                }
            },

            /*------------------------------------------------------*/
            /*--------------- RUTAS PARA CRUD ADDONS ---------------*/
            /*------------------------------------------------------*/

            {
                path: 'addons',
                name: 'Addons',
                component: Addons,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: 'addon/create',
                name: 'CreateAddon',
                component: CreateAddon,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: 'addon/edit/:id',
                name: 'EditAddon',
                component: CreateAddon,
                meta: {
                    requireAuth: true
                }
            },
            /*------------------------------------------------------*/
            /*--------------- RUTAS PARA CRUD LOGS ---------------*/
            /*------------------------------------------------------*/

            {
                path: 'logs/:slug',
                name: 'Logs',
                component: Logs,
                meta: {
                    requireAuth: true
                }
            },

            {
                path: 'property',
                name: 'Property',
                component: Property,
                meta: {
                    requireAuth: true
                }
            },

            {
                path: 'property/information/:id',
                name: 'PropertyShow',
                component: Property,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: 'property-data',
                name: 'DataProperty',
                component: DataProperty,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: 'property-resume',
                name: 'ResumeProperty',
                component: ResumeProperty,
                meta: {
                    requireAuth: true
                }
            },

            /*------------------------------------------------------*/
            /*--------------- ROUTE PARA CRUD PROPERTIES -----------*/
            /*------------------------------------------------------*/

            {
                path: 'properties',
                name: 'Properties',
                component: Properties,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: 'properties/create',
                name: 'createProperty',
                component: CreateProperty,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: 'properties/edit/:id',
                name: 'editProperty',
                component: CreateProperty,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: 'property/terms-and-politics',
                name: 'indexTerms',
                component: indexTerms,//editTerms
                meta: {
                    requireAuth: true
                }
            },
            {
                path: 'property/privacy-hotel/edit/:id',
                name: 'editPrivacis',
                component: editTerms,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: 'property/terms-and-politics/edit/:id',
                name: 'editTerms',
                component: editTerms,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: 'property/payment-politics/edit/:id',
                name: 'editPolitics',
                component: editTerms,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: 'property/configurations',
                name: 'configurationProperty',
                component: configurationProperty,//editTerms
                meta: {
                    requireAuth: true
                }
            },

            /*------------------------------------------------------*/
            /*--------------- ROUTE PARA INTEGRATIONS --------------*/
            /*------------------------------------------------------*/

            {
                path: 'integrations/configuration-ratetiger',
                name: 'integrationRatetiger',
                component: IntegrationRatetiger,
                meta: {
                    requireAuth: true
                }
            },







            /*------------------------------------------------------*/
            /*--------------- ROUTE PARA CRUD ROOMS ----------------*/
            /*------------------------------------------------------*/

            {
                path: 'rooms',
                name: 'Rooms',
                component: Rooms,
                meta: {
                    requireAuth: true
                }
            },

            {
                path: 'rooms/create',
                name: 'createRoom',
                component: CreateRoom,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: 'rooms/edit/:id',
                name: 'editRoom',
                component: CreateRoom,
                meta: {
                    requireAuth: true
                }
            },

            /*------------------------------------------------------*/
            /*--------------- ROUTE PARA CRUD POLITICS -------------*/
            /*------------------------------------------------------*/

            {
                path: 'annulments',
                name: 'annulments',
                component: Annulments,
                meta: {
                    requireAuth: true
                }
            },

            {
                path: 'annulments/create',
                name: 'createAnnulment',
                component: CreateAnnulments,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: 'annulments/:id',
                name: 'editAnnulment',
                component: CreateAnnulments,
                meta: {
                    requireAuth: true
                }
            },
        ]
    },
    {path: '*', component: NotFound}
]

const router = new VueRouter({
  mode: "history",
  routes
});

router.beforeEach((to, from, next) => {
  const login_path = (to.path === "/" || to.path === "/login");
  // const { authorize } = to.meta;
  const userLogged = JSON.parse(localStorage.getItem("user"));

  // if (authorize) {
    //si si tiene restricciones la ruta
    if (!userLogged && !login_path) {
      console.log('aquí debería ir');
      //y no esta logeado entonces a logear
      return next({
        path: "/login"
      });
    }
    //checar si la routa esta restringida por algun role_id y  NO coincide el role_id del user logeado
    // if (authorize.length && !authorize.includes(userLogged.role_id)) {
    //   return next({ name: "Properties" });
    // }
  // }
  if (login_path && userLogged) {
    return next({ name: "Properties" });
  }
  next();
});
export default router
