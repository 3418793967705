const appSettings = window.localStorage;
const KEY = 'app_settings';

export default class {

    constructor() {
        this.settings = appSettings.getItem(KEY) || '[]';
        this.settings = JSON.parse(this.settings);
    }

    hasValue(key) {

        return this.settings.some(e => e.key === key);
    }

    getValue(key) {

        let setting = this.findSetting(key);

        return setting ? setting.value : null;
    }

    setValue(key, value) {

        let save = false;
        let setting = this.findSetting(key);

        if (setting) {

            this.settings.forEach((itm) => {
                if (itm.key === key) {
                    itm.value = value;
                }
            });

            save = true;

        } else {

            this.settings.push({
                key,
                value
            });

            save = true;
        }

        return this.updateSettings(save);

    }

    setMultiple(array) {

        let save = false;
        if (Array.isArray(array)) {

            this.mergeSettings(array);
            save = true;
        }

        return this.updateSettings(save);
    }

    findSetting(key) {

        if (this.settings.length) {
            return this.settings.find(setting => setting.key === key);
        }

        return false;
    }

    updateSettings(save = false) {

        let self = this;

        return new Promise(resolve => {

            if (save) {

                let updated_settings = JSON.stringify(self.settings);

                appSettings.setItem(KEY, updated_settings);

                resolve();

            } else {

                resolve(false);

            }

        });

    }

    getSettings() {
        return this.settings;
    }

    mergeSettings(arraySettings) {

        let reduced =  this.settings.filter( aitem => ! arraySettings.find ( bitem => aitem.key === bitem.key) );
        this.settings = reduced.concat(arraySettings);

    }

    removeSetting(key) {

        this.settings = this.settings.filter(setting => setting.key !== key);
        return this.updateSettings(true);
    }

    clearAllSettings() {

        return new Promise(resolve => {
            appSettings.remove(KEY);
            resolve(true);
        })
    }

}
