import BackendService from './BackendService';
const singleName = 'log';
const pluralName = singleName+'s';




export default class LogService extends BackendService {

    constructor() {
        super();
    }


    create(data) {


        //console.log('esto se envio::',  data);
        //return data;
        return this.httpCall(singleName,{
            method: 'POST',
            data: data,
        });

    }



    getList(params) {
        //console.log('get rooms');

        //return testItems;
        return this.httpCall(pluralName,{
            params: params,
        });
    }



}
