import axios from "axios";
import ApiService from "@/services/ApiService.js";
import PropertyService from "../../js/services/PropertyService";
import LogService from '../../js/services/LogService';
import LogFunctions from '../../js/functions/LogFunctions';
import moment from 'moment-timezone';
const logFunctions = new LogFunctions();
const propertyService = new PropertyService();
const logService = new LogService();

export const state = {
  currentUser: JSON.parse(localStorage.getItem("user")) || "",
  token: localStorage.getItem("token") || "",
  changesHistory: false,
  checkedView: false,
  selected_property:  JSON.parse(localStorage.getItem("property")) || "",
  properties:  JSON.parse(localStorage.getItem("properties")) || "",
  section:'PROPERTY',
  locale:  localStorage.getItem("locale") || "es",
  writting_allow:  false,
  sections: {
    ROOMS_RATES: 0,
    RESERVATIONS: 1,
    MARKETING:2,  
    REVENUE: 3,
    CONTA: 4,
    PROPERTY: 5,
  },
};

export const mutations = {
  AUTH_SUCCESS({ context }, payload) {
    state.loading = false;
    state.token = payload.token;
    state.currentUser = Object.assign({}, payload.user);
    state.properties = [];

    // guardamos los datos.
    localStorage.setItem("token", state.token);
    localStorage.setItem("user", JSON.stringify(state.currentUser));
    // limpiamos las propiedades
    localStorage.removeItem("properties");

     //console.log('**** RESPONSE', localStorage.getItem("token"));
    // Ponemos el token para que se mande en todas la peticiones
    axios.defaults.headers.common["token"] = localStorage.getItem("token");
  },
  AUTH_LOGOUT(state, rootState) {
    state.loading = false;
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("properties");
    localStorage.removeItem("property");
    delete axios.defaults.headers.common["token"];
    state.token = "";
    state.currentUser = {};
    state.properties = [];
    state.property = '';
  },
  AUTH_PUSH_PROPERTY({context},payload){
    // agregamos la propiedad al estado
    // console.log('++++ property',payload)
    state.properties.push({
      '_id': payload._id,
      'logo': payload.logo,
      'name':payload.name,
      'category':payload.category,
      'city':payload.city,
      'state':payload.state,
      'country':payload.country,
      'status':payload.status,
        'booking_url':payload.booking_url,
        'age_child':payload.age_child,
        'age_jr':payload.age_jr,
        'only_adults':payload.only_adults,
        'plan_ep':payload.plan_ep,
        'plan_epb':payload.plan_epb,
        'plan_ai':payload.plan_ai,
        'privacy':payload.privacy,
        'terms':payload.terms,
        'payment_policy':payload.payment_policy,
      'place': payload.city + ', ' + payload.state + ' ' + payload.country
    })
    localStorage.setItem("properties", JSON.stringify(state.properties));
  },
  // Seleccionar propiedad actual
  SET_MAIN_PROPERTY({context},property){
    // console.log('property',property)
    state.selected_property = Object.assign({}, property);
    localStorage.setItem("property", JSON.stringify(state.selected_property));
  },
  SET_CHECKED_VIEW({context},status){
    state.checkedView = status;

    console.log('checkedView',state.checkedView)
  },

    SET_ADD_PROPERTY({context},property){
        // console.log('property',property)
        state.selected_property = Object.assign({}, property);
        localStorage.setItem("property", JSON.stringify(state.selected_property));
    },
  SET_SECTION({context},section){
    state.section = section;
  },
  WRITTING_ALLOW({context},value){
    state.writting_allow = value;
  },

};

// Reglas de negocio
export const actions = {
  LOGIN({ commit }, credentials) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", true);
      ApiService.login(credentials)
        .then( async response => {
          //console.log('**** RESPONSE',response.data.data);
          commit("SET_LOADING", false);
          commit("CLEAR_NOTIFICATION");
          await commit("AUTH_SUCCESS", response.data.data);


          let paramsLog= {
            action: 3,
            section: logFunctions.findSection('user')._id,
            property_id: null,
            data: [],
            date: moment().format('YYYY-MM-DD HH:mm:ss'),
            object_id: response.data.data.user._id,
            user_id: response.data.data.user._id,
          };
          await logService.create(paramsLog);

           //console.log('**** RESPONSE TOKEN',response.data.data.token);
            //localStorage.setItem("token", response.data.data.token);

          // Si es super admin que agarre todas las propiedades
          if(response.data.data.user.is_admin){
            // console.log('****admin porwer')

              propertyService.getProperties().then(response => {


                //console.log('****token', localStorage.getItem("token"), state.token)
              response.data.data.properties.forEach(function(property){
                  //console.log('****property', property)
                commit("AUTH_PUSH_PROPERTY", property);
              });
              commit("SET_MAIN_PROPERTY",state.properties[0]);

                resolve(response);
            });
          }else{
            //console.log('**** limitadinho');
            // si no que revise a cuales tiene permiso
            // obtenemos los datos de cada propiedad

             //console.log('****user', response.data.data.user)
            response.data.data.user.permissions.forEach(function(permission,index){

               //console.log('****permission', permission)
              //ApiService.getProperty(permission.property).then(response => {
                propertyService.getPropertyId(permission.property).then(response => {
                 //console.log('****property', response)
                commit("AUTH_PUSH_PROPERTY", response.data.data.property);
                if(index==0){
                  commit("SET_MAIN_PROPERTY",state.properties[0]);
                }
                resolve(response);
              });
            });
          }


        })
        .catch(error => {
          commit("SET_LOADING", false);
          commit("AUTH_LOGOUT");
          reject(error);
        });
    });
  },
  async LOGOUT({ commit, state }) {
    if(state.currentUser && state.currentUser !== ''){
      let paramsLog= {
        action: 4,
        section: logFunctions.findSection('user')._id,
        property_id: null,
        data: [],
        date: moment().format('YYYY-MM-DD HH:mm:ss'),
        object_id: state.currentUser._id,
        user_id: state.currentUser._id,
      };
      await logService.create(paramsLog);
    }
    commit("SET_LOADING", true);
    commit("SET_LOADING", false);
    commit("CLEAR_NOTIFICATION");
    commit("AUTH_LOGOUT");
  },
  ACTUALIZAR_PASSWORD({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", true);
      ApiService.updatePassword(data)
        .then(response => {
          commit("SET_LOADING", false);
          resolve(response);
        })
        .catch(error => {
          commit("SET_LOADING", false);
          reject(error);
        });
    });
  },
  SELECT_PROPERTY({commit},data){
    return new Promise((resolve, reject) => {
      commit("SET_MAIN_PROPERTY",data);
    });
  },

  CHECKED_VIEW({commit},status){
    return new Promise((resolve, reject) => {
      commit("SET_CHECKED_VIEW", status);
    });
  },


    ADD_PROPERTY({commit},data){
       //console.log('****admin data', data)
        return new Promise((resolve, reject) => {

            state.properties = [];
            localStorage.setItem("properties", []);
            if(state.currentUser.is_admin){
               //console.log('****admin porwer')

                ApiService.listProperties().then(response => {
                    response.data.data.properties.forEach(function(property){
                        commit("AUTH_PUSH_PROPERTY", property);
                    });
                    commit("SET_MAIN_PROPERTY",data);

                    resolve(response);
                });
            }else{
               //console.log('**** limitadinho');
                // si no que revise a cuales tiene permiso
                // obtenemos los datos de cada propiedad
                response.data.data.user.permissions.forEach(function(permission,index){
                    ApiService.getProperty(permission.property).then(response => {
                        commit("AUTH_PUSH_PROPERTY", response.data.data.property);
                        if(index==0){
                            commit("SET_MAIN_PROPERTY", data);
                        }
                        resolve(response);
                    });
                });
            }



            //commit("SET_MAIN_PROPERTY",data);
        });
    },
    UPDATE_PROPERTY({commit},data){
       //console.log('****admin data')
       //console.log('token', localStorage.getItem("token"))
        return new Promise((resolve, reject) => {

            state.properties = [];
            localStorage.setItem("properties", []);
            if(state.currentUser.is_admin){
               //console.log('****admin porwer')
                ApiService.listProperties().then(response => {
                    response.data.data.properties.forEach(function(property){
                        commit("AUTH_PUSH_PROPERTY", property);
                    });

                    commit("SET_MAIN_PROPERTY", data);

                    resolve(response);
                });
            }else{
               //console.log('**** limitadinho');
                // si no que revise a cuales tiene permiso
                // obtenemos los datos de cada propiedad
                response.data.data.user.permissions.forEach(function(permission,index){
                    ApiService.getProperty(permission.property).then(response => {
                        commit("AUTH_PUSH_PROPERTY", response.data.data.property);
                        resolve(response);
                    });
                });

                commit("SET_MAIN_PROPERTY", data);
            }



            //commit("SET_MAIN_PROPERTY",data);
        });
    },


  CHANGE_SECTION({ commit }, data){
    return new Promise((resolve, reject) => {
      // Cambiamos la sección
      commit("SET_SECTION",data);
      // Revisamos los permisos
      let section_value =state.sections[data];
      let writting_val = false;
      if(state.currentUser.is_admin){
        writting_val = true;
      }else if(section_value!=undefined){
        // obtenemos los permisos
        let permissions =  state.currentUser.permissions.find(function(permission){
          return permission.property === state.selected_property._id;
        });
        writting_val = (permissions.sections[section_value] === 2);
      }

      commit("WRITTING_ALLOW",writting_val);
    });
  },
    CHANGES_ACTIVES({commit}, rootState) {
        state.changesHistory = rootState;
    },
};
export const getters = {
  isLoggedIn: state => !!state.token,
  currentUser: state => state.currentUser,
    changesHistory: state => state.changesHistory,
    checkedView: state => state.checkedView,
  roleUser: state => state.currentUser.role_id || false,
  selected_property: state => state.selected_property || false,
  properties: state => state.properties || [],
  permissions: state => {
    if(state.currentUser.permissions){
      return state.currentUser.permissions.find(function(permission){
        return permission.property === state.selected_property._id;
      });
    }else{
      return false;
    }
  },
  section: state => state.section,
  locale:  state => state.locale,
  sections: state => state.sections,
  writting_permission: state => state.writting_allow,
};
