
export const revenueTranslate = {




    es: {

        index: {
            home: 'Inicio',
            title : 'Tarifas dinámicas',
            new: 'Crear nueva tarifa dinamica',
            name: 'Nombre',
            period: 'Periodo',
            rateplans: 'Contratos',
            conditions: 'Condiciones de las habitaciones',
            actions: 'Acciones',
            pause: 'Pausar',
            has: 'Tiene',
            a_has_plural: 'tarifas dinámicas activas',
            a_has_singular: 'tarifas dinámicas activa',
            c_has_plural: 'tarifas caducadas',
            c_has_singular: 'tarifa caducada',
            i_has_plural: 'tarifas dinámicas inactivas',
            i_has_singular: 'tarifas dinámicas inactiva',
            search: 'Buscar tarifas dinámicas',

        },
        filter:{
            vigencies: 'Vigentes',
            caducated: 'Vencidas',
            futures: 'Futuras',
            on_pause: 'En pausa',
            inactives: 'Inactivas',
        },
        new:{
            title : 'Revenue',
            header : 'Crear nueva regla',
            modal: '¡Operación exitosa!',
            button:  'Crear tarifa dinamica',
            msg: 'Tarifa dinamica guardada'
        },
        edit: {
            header : 'Editar tarifa dinamica',
            button:  'Actualizar tarifa dinamica',
            msg: 'Tarifa dinamica actualizada'

        },
        form:{
            name: 'Nombre de la tarifa dinamica',
            rateplans: 'Seleccionar contratos',
            conditions: 'Crear condiciones'
            /*minimun_nights: {
               title : 'Mínimo de noches de estancia requerida',
                min: 'Mínimo de noches',
                max: 'Máximo de noches',
                add: '+ Agregar máximo de noches requeridas',
                delete: '- Eliminar maximo de noches',
            },

            days_advaces:{
                title : 'Antelación de días',
                advance: 'Reserva anticipada',
                today: 'Mismo día',
                min: 'Debe reserva al menos',
                max: 'Puede reservar un maximo',
                add: '+ Agregar maximo días de restricción',
                days_text: 'días con antelación',
                delete: '- Eliminar maximo de noches',
                can_reserve: 'Pueden reservar',
                text_hour: 'hora(s) antes del final del día del viaje',
                info_show: 'Tu promoción aparecerá a las 00:00 am en cada día de viaje.'
            },
            promotion: {
                title: 'Promoción',
                alert: '',
                type_question: '¿Qué tipo de descuento le gustaría ofrecer?',
                unique: 'Un solo descuento ',
                unique_description: 'Proporcionar un descuento fijo en el importe total de la reserva para una estancia.',
                each_day: 'Un descuento diariamente',
                each_description: 'Ofrecer un porcentaje diferente de descuento (hasta el 75%) para cada día de la semana.',
                always_day: 'Descuento de varias noches',
                always_description: 'Ofrecer una noche gratis o con descuento a los huéspedes que se queden un tiempo determinado.',
                type: {
                    title: 'Tipo',
                    discount: 'Descuento',
                    percent: 'Porcentaje',
                    description: 'Se aplica a todas las noches de la estancia del huésped',
                    legend: 'Elija un descuento entre 5% y 75%',
                    tip: 'Agregar un descuento entre 1% y el 75%',
                },
                add: '+ Agregar límite de reservaciones (inventario)',
                limit: 'Límite de reservaciones',
                limit_one: 'Limitar a una vez por estancia',
                room_days: 'Habitaciones por día',
                apply: 'Aplicar a',
                apply_legend: 'Todas las habitaciones y tarifas',
                quesion_1: '¿Quieres ofrecer una noche gratis, o una noche con descuento?',
                quesion_2: '¿Qué noche de estancia de un huésped será descontada?',
                quesion_3: '¿Qué noche de estancia de un huésped será con descuento?',
                percent_discount: 'Porcentaje de descuento'
            },

            booking: {
                title:'Booking Window',
                clients: {
                    title: 'Los clientes podrán reservar',
                    option_1: 'Todo el tiempo',
                    option_2: 'Días y horas específicas'
                },
                a: 'a',
                from: 'Desde',
                to: 'Hasta',
                only_days: 'Sólo en los siguientes días:',
                between_time: 'Entre este tiempo'
            },
            travel: {
                title:'Travel Window',
                add: 'Agregar fechas de bloqueos (max 2 años)',
                reset: 'Reestablecer',
                date_selected: 'Fechas seleccionadas',
                blocks: 'Bloqueos'
            },
            info:{
                title:'Nombre de la promoción',
                name: 'Nombre',
                description: 'El nombre de la promoción es visible para los viajeros, se muestra en el icono a lado del descuento.'

            }*/

        },
        button:{
            next :'Siguiente',
            edit :'Editar',
            save: 'Crear tarifa dinamica',
            cancel: 'Cancelar'
        }
    },

    en: {
    },
};