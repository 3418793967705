
export const loginTranslate = {




    es: {

        login: 'Inicio de sesión',
        email: 'Correo Electrónico',
        password: 'Contraseña',
        button: 'Iniciar',
        enter_email: 'Ingresar correo electrónico',
        enter_password: 'Ingresar contraseña ',
        error: 'Usuario o contraseña incorrectos',
        fillup: 'Ingresa usuario y contraseña'

    },

    en: {

        login: 'Login',
        email: 'Email address',
        password: 'Password',
        button: 'Login',
        enter_email: 'Enter email',
        enter_password: 'Enter password',
        error: 'User or password are invalid',
        fillup: 'Enter username and password'

    },
};