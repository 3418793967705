
export const integrationTranslate = {




    es: {
        title: 'Configuración general Ratetiger',
        checkbox: 'Conectar channel manager',
        field_address: 'Address',
        field_password: 'Password',
        field_user: 'Username',
        hotel_id: 'Hotel ID',

        rateplan:{
            title: 'Contratos conectados',
            name: 'Nombre',
            code: 'Código Nextohotel',
            code_ratetiger: 'Código Ratetiger',
            add_element: '+ Agregar contrato',
        },
        room:{
            title: 'Habitaciones conectadas',
            name: 'Nombre',
            code: 'Código Nextohotel',
            code_ratetiger: 'Código Ratetiger',
            add_element: '+ Agregar habitación',
        }

    },

    en: {
    },
};