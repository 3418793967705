export const formTranslate = {




    es: {
        email: 'Correo electrónico',
        name: 'Nombre',
        lastname: 'Apellidos',
        pre_phone: 'Prefijo telefónico nacional fijo',
        pre_cellphone: 'Prefijo telefónico nacional para móviles',
        phone_fijo: 'Número de teléfono fijo',
        phone_movil: 'Número de teléfono móvil',
        cancel: 'Cancelar',
        showing_a : 'Mostrando',
        showing_b : 'a',
        showing_c : 'de',
        showing_d : 'entradas',
        search_records : 'Buscar registros',
        actions: 'Acciones',
        permises: 'Permisos',
        edit: 'Editar',
        delete: 'Eliminar',
        update: 'Actualizar',
        password: 'Contraseña',
        question_delete: '¿Estás seguro de continuar?',
        warning_delete: '¡No podrás revertir esto!',
        deleted: '¡Eliminado!',
        been_deleted: 'El usuario ha sido eliminado',
        yes_deleted: 'Si, eliminarlo',

        category: 'Categoría',
        country: 'Pais',
        state: 'Estado',
        city: 'Ciudad',
        street: 'Calle',
        int_number: 'Número interior',
        ext_number: 'Número exterior',
        suburb: 'Colonia',
        zipcode: 'Código postal',
        site_url: 'Url del sitio',
        destiny: 'Destino',
        phone: 'Teléfono',
        ubication: 'Ubicación',
        amenities: 'Amenidades',
        stars: 'Estrellas',
        description: 'Descripción',
        filter: 'Filtrar',
        save: 'Guardar',
        show: 'Ver',
        lada: 'lada',
        resend: 'Reenvíar E-mail',


        reservations: 'Reservaciones',
        status: 'Estado',
        actives: 'Activos',
        inactives: 'Inactivos',


        number_phone: 'Número telefónico',
        contact_data: 'Datos de contacto',
        cdfi_use: 'Uso de CDFI',
        rfc: 'RFC',
        razon: 'Razón social',
        mark: 'Marca',
        phone_property: 'Teléfono de la propiedad',
        website: 'Sitio web',

        iva: 'Impuesto del Valor Agregado (IVA)',
        ish: 'Impuesto Sobre Hospedaje(IHG)',
        ratetiger_account_id: 'Channel manager',
        error:{
            room:{
                min: 'Es necesario agregar una habitación para continuar' ,
                required: 'Se deben llenar todos los campos antes de continuar',
                number: 'Es necesario agregar cantidades validas para continuar',
            }
        },






    },

    en: {
        edit: 'Edit',
        delete: 'Delete',
        password: 'Password',
        question_delete: 'Are you sure?',
        warning_delete: 'You won\'t be able to revert this!',
        deleted: 'Deleted!',
        been_deleted: 'Your file has been deleted',
        yes_deleted: 'Yes, delete it!'
    },
};
