
export const propertyTranslate = {




    es: {

        index: {
            home: 'Inicio ',
            property: 'Propiedad',
            title : 'Propiedades',
            header : 'Administrar propiedades',
            new: 'Agregar propiedad',
            new_tooltip: 'Nueva preopiedad ',
            search: 'Buscar propiedades'
        },
        index_one:{
            header : 'Administrar propiedad',
        },
        data:{
            header : 'Datos de la propiedad',
            msg_top: 'Datos fiscales actualizados',
            msg_bottom: 'Datos de contacto actualizados'
        },

        new:{
            header : 'Crear nueva propiedad',
            button:  'Guardar',
            modal: '¡Operación exitosa!',
            msg: 'Propiedad guardada'
        },
        edit: {
            header : 'Editar propiedad',
            button:  'Actualizar',
            msg: 'Propiedad actualizada'

        },
        form:{
            name_lang: 'Nombre (inglés)',
            description_lang: 'Descripción (inglés)',
            site_url_lang: 'Url del sitio (inglés)'
        },
        table:{
            pendient: 'Pendientes de confirmar',
            arrive: 'Llegadas',
            cancelled: 'Cancelaciones',
            facture: 'Facturas',
            report: 'Reporte de pagos',
            comision_today: 'Comisiones de hoy',
            reservation_today: 'Reservaciones de hoy',

        },
        roles:{
            title: 'Roles',
            rooms: 'Habitaciones y tarifas',
            marketing: 'Marketing',
            accounting: 'Contabilidad',
            reservations: 'Reservaciones',
            revenue: 'Revenue',
            properties: 'Propiedad'


        },
        terms:{
            privacy: 'Políticas de privacidad del hotel',
            terms: 'Términos y condiciones',
            politics: 'Políticas de pago',
            title: 'Políticas generales',
            set_privacy: 'Actualizar políticas de privacidad del hotel',
            set_terms: 'Actualizar términos y condiciones',
            set_politics: 'Actualizar políticas de pago'
        },
        config:{
            type_change: 'Tipo de cambio',
            plan_food: 'Planes de alimentos',
            payments: 'Configuración de Pasarela de pagos y PayPal',
            hotel_types: 'Tipo de hotel',
            custom_skin: 'Personalización de colores de la vista del motor',
            verification: 'Verificación tarjeta de credito',
            type_pasarela: 'Pasarela de pago (OPENPAY)',
            type_firstdata: 'Pasarela de pago (FIRSTDATA)',
            type_stripe: 'Pasarela de pago (STRIPE)',
            type_paypal: 'PayPal',
            type_phv: 'Pago en hotel (verificación de tarjetas)',
            type_phs: 'Pago en hotel (sin verificación de tarjeta)',
            only_adults: 'Solo para Adultos',
            age_config: 'Configuración de edades',
            infantes: 'Infantes (años)',
            kids: 'Niños (años)',
            jrs: 'Jrs (años)',
            on: 'a',
            c_header: 'Color header',
            c_primary: 'Color principal',
            c_secundary: 'Color secundario',
            msg: 'Configuración guardada'


        }

    },

    en: {
    },
};