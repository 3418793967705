
export const reservationTranslate = {




    es: {

        index: {
            title : 'Reservación',
            search: 'Buscar por:',
            recents: 'Reservas recientes',
            cancels: 'Canceladas',
            inconfirm: 'Sin confirmar',
            advanced_search: 'Búsqueda avanzada',
            reservs: 'Reservadas',
            confirms: 'Confirmadas',
            payment_hotel: 'Pago en hotel',
            arrives: 'Llegadas',
            in_stays: 'Durante la estancia',
            departures: 'Salidas',
            from: 'Desde',
            to: 'Hasta',
            id_reserve: 'ID reservación',
            confirmate_number: '# Confirmación',
            name: 'Nombre',
            actions: 'Acciones',
            filter_for: 'Filtrar por',
            search_for: 'Buscar por',
        },
        breadcrumb:{
            index: 'Reservaciones',
            resume: ' Resúmen de la reservación',
            cancel: ' Resúmen de la reservación cancelada',
            edit: ' Editar información de la reservación'
        },
        table:{
            guest: 'Huésped',
            arrive: 'Llegada',
            departure: 'Salida',
            date_reserve: 'Fecha de reserva',
            room: 'Habitación',
            reserva_id: 'ID reserva',
            cod_confirm: 'Código de confirmación',
            total: 'Total de la reserva',
            addons: 'Servicios extra agregados'

        },
        insides: {
            resumen:{
                title: 'Resúmen de la reservación cancelada',
                headers:{
                    info: 'Información de la reservación',
                    client: 'Información del huesped',
                    payment: 'Información del pago',
                    history: 'Historial de la reserva',
                    politics: 'Políticas de cancelación',
                    payments: 'Políticas de pago'
                }

            },
            update:{
                button: 'Actualizar reservación',
                msg: 'Reservación actualizada',
                header : 'Editar reservación',
                modal: '¡Operación exitosa!',
            },
        },
    },

    en: {
    },
};