import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);


import  {loginTranslate} from './translate/login.js'
import  {userTranslate} from './translate/users.js'
import  {formTranslate} from './translate/form.js'
import  {sidebarTranslate} from './translate/sidebar.js'
import  {propertyTranslate} from './translate/properties.js'
import  {roomTranslate} from './translate/rooms.js'
import  {contractTranslate} from './translate/contracts.js'
import  {annulmentTranslate} from './translate/annulments.js'
import  {rateTranslate} from './translate/rates.js'
import  {reservationTranslate} from './translate/reservations.js'
import  {promotionTranslate} from './translate/promotions.js'
import  {revenueTranslate} from './translate/revenues.js'
import  {landingTranslate} from './translate/landings.js'
import  {addonTranslate} from './translate/addons.js'
import  {logTranslate} from './translate/logs.js'
import  {integrationTranslate} from './translate/integration.js'


export const i18n = new VueI18n({
    locale: 'es',
    fallbackLocale: 'en',
    messages:{
        en: {
            form: {
                ...formTranslate.en,
            },
            login: {
                ...loginTranslate.en,
            },
            sidebar: {
                ...sidebarTranslate.en,
            },
            user: {
                ...userTranslate.en,
            },
            property: {
                ...propertyTranslate.en,
            },
            room: {
                ...roomTranslate.en,
            },
            contract: {
                ...contractTranslate.en,
            },
            annulment: {
                ...annulmentTranslate.en,
            },

            rate: {
                ...rateTranslate.en,
            },

            reservation: {
                ...reservationTranslate.en,
            },

            revenue: {
                ...revenueTranslate.en,
            },
            promotion: {
                ...promotionTranslate.en,
            },
            landing: {
                ...landingTranslate.en,
            },

            addon: {
                ...addonTranslate.en,
            },
            log: {
                ...logTranslate.en,
            },

            integration: {
                ...integrationTranslate.en,
            },


            locale_es: 'Spanish',
            locale_en: 'English',
        },

        es: {
            locale_es: 'Español',
            locale_en: 'Inglés',
            form: {
                ...formTranslate.es,
            },
            login: {
                ...loginTranslate.es,
            },
            sidebar: {
                ...sidebarTranslate.es,
            },
            user: {
                ...userTranslate.es,
            },
            property: {
                ...propertyTranslate.es,
            },
            room: {
                ...roomTranslate.es,
            },
            contract: {
                ...contractTranslate.es,
            },
            annulment: {
                ...annulmentTranslate.es,
            },

            rate: {
                ...rateTranslate.es,
            },

            reservation: {
                ...reservationTranslate.es,
            },

            revenue: {
                ...revenueTranslate.es,
            },

            promotion: {
                ...promotionTranslate.es,
            },
            landing: {
                ...landingTranslate.es,
            },
            addon: {
                ...addonTranslate.es,
            },
            log: {
                ...logTranslate.es,
            },

            integration: {
                ...integrationTranslate.es,
            },
        }

    }
});