<template>
    <div class="logo">
        <div class="navbar-box">
            <div class="navbar-minimize">
                <button class="btn btn-outline btn-fill btn-round btn-icon d-none d-lg-block" @click="minimizeSidebar">
                    <i :class="$sidebar.isMinimized ? 'fa fa-ellipsis-v' : 'fa fa-navicon'"></i>
                </button>
            </div>
            <!--<a class="navbar-brand">{{this.$route.name}}</a>-->
            <button type="button"
                    class="navbar-toggler navbar-toggler-right"
                    :class="{toggled: $sidebar.showSidebar}"
                    aria-expanded="false"
                    @click="toggleSidebar">
                <span class="navbar-toggler-bar burger-lines"></span>
                <span class="navbar-toggler-bar burger-lines"></span>
                <span class="navbar-toggler-bar burger-lines"></span>
            </button>
        </div>


        <div class="info">
            <a data-toggle="collapse" :aria-expanded="!isClosed" @click.stop="toggleMenu" href="#">
                <!--<span>
                  {{title}}
               </span>-->
            </a>
            <div class="clearfix"></div>
        </div>
    </div>
</template>
<script>
    import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'

    export default {
        components: {
            [CollapseTransition.name]: CollapseTransition
        },
        props: {
            title: {
                type: String,
                default: 'Tania Andrew'
            }
        },
        data() {
            return {
                isClosed: true
            }
        },
        created() {},
        methods: {

            toggleNotificationDropDown() {
                this.activeNotifications = !this.activeNotifications
            },
            closeDropDown() {
                this.activeNotifications = false
            },
            toggleSidebar() {
                this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
            },
            hideSidebar() {
                this.$sidebar.displaySidebar(false)
            },
            minimizeSidebar() {
                this.$sidebar.toggleMinimize()
            },
            toggleMenu() {
                this.isClosed = !this.isClosed
            },
        },

        mounted() { }
    }
</script>
<style lang="scss">
    .collapsed {
        transition: opacity 1s;
    }
    .navbar-box{
        .navbar-minimize{
            margin-right: 0;
            text-align: center;
            .btn-round{
                margin: auto;
            }

        }
    }

    .sidebar-mini{
        .sidebar:hover{
            width: 80px;
            .sidebar-wrapper{
                width: 80px;
                .nav{
                    .nav-link{
                        p{
                            opacity: 0;
                        }

                        span.sidebar-normal{
                            opacity: 0 !important;
                        }
                    }
                }
            }
        }
    }

    .sidebar {
        .logo {
            padding: 15px 0 0;
            &:before {
                right: 0;
                width: 100%;
                background: transparent;
            }
            .img-logo {
                display: inline-block;
                height: 70px;
                padding: 10px 0;

            }
        }

    }
</style>
