<template>
    <auth-layout pageClass="login-page">
        <div class="row d-flex justify-content-center align-items-center">
            <vue-topprogress ref="topProgress2" color="#fff"></vue-topprogress>
            <div class="col-lg-4 col-md-6 col-sm-8">
                <form @submit.prevent="login" role="form" action="#">
                    <!--You can specify transitions on initial render. The `card-hidden` class will be present initially and then it will be removed-->
                    <fade-render-transition>
                        <card>
                            <div slot="header">

                                <div class="col-12 text-center mb-2 p-0">
                                    <img src="static/logo.png" style="width: 80%;">
                                </div>


                                <h3 class="card-title text-center">{{$t('login.login')}}</h3>
                            </div>
                            <div>
                                <l-alert type="danger" dismissible="" v-if="error.status===1">
                                  <span >
                                     {{error.message}}
                                  </span>
                                </l-alert>
                                <fg-input :label="$t('login.email')"
                                          :placeholder="$t('login.enter_email')"
                                          type="email"
                                          v-model="user.email">

                                </fg-input>
                                <fg-input :label="$t('login.password')"
                                          type="password"
                                          :placeholder="$t('login.enter_password')"
                                          v-model="user.password">
                                </fg-input>
                            </div>
                            <div class="text-center">
                                <button type="submit" class="btn btn-fill  btn-round btn-wd btn-login">
                                    {{$t('login.button')}}
                                </button>
                                <br>
                                <div class="forgot">
                                    <!--<router-link to="/register" class="card-category">
                                      Forgot your password?
                                    </router-link>-->
                                </div>
                            </div>
                        </card>
                    </fade-render-transition>

                </form>
            </div>
        </div>
    </auth-layout>
</template>
<script>
    import {Checkbox as LCheckbox, FadeRenderTransition} from 'src/components/index'
    import {Loading } from 'element-ui'
    import AuthLayout from './AuthLayout.vue'
    // import LoginService from '../../../js/services/LoginService';
    // import Settings from "../../../js/helpers/Settings";
    import EventBus from "src/js/helpers/EventBus";
    import { vueTopprogress } from 'vue-top-progress';
    import { mapGetters } from "vuex";  

    import LAlert from 'src/components/Alert';
    import NotificationSample from 'src/pages/Dashboard/Components/NotificationSample';
    //import LogService from '../../../js/services/LogService';
    //const logService = new LogService();*/



    // let settings = new Settings();

    // const loginService = new LoginService();
    export default {
        components: {
            LAlert,
            LCheckbox,
            FadeRenderTransition,
            AuthLayout,
            vueTopprogress
        },
        data() {
            return {
                screenLoading: false,
                screenText: '',
                user: {
                    email: '',
                    password: '',
                    subscribe: true
                },
                error: {
                    status: '',
                    message: '',
                }
            }
        },

        computed: {
          ...mapGetters(["currentUser"]),
        },
        created(){
            this.initPage();
        },
        methods: {
            initPage(){
                this.closeLoading();
            },
            login(){
                this.error.status = 0;
                this.$refs.topProgress2.start();
                if (this.user.email !== '' &&  this.user.password !== '') {
                    let email = this.user.email;
                    let password = this.user.password;
                    this.$store
                        .dispatch("LOGIN", {
                            email: email,
                            password: password

                        })
                        .then( async () => {    
                            this.$router.push("/admin/properties");
                        })
                        .catch(error => {
                            this.$refs.topProgress2.done()
                            this.error.message = this.$t('login.error');
                            this.error.status = 1;
                        });
                }
                else {
                    setTimeout(() => {
                        this.error.message = this.$t('login.fillup');
                        this.error.status = 1;
                    }, 500);
                }


            },
            async createLog(object_id, action, data = []){
                let paramsLog= this.log_page;
                paramsLog.object_id = object_id;
                paramsLog.section = logFunctions.findSection('user')._id
                paramsLog.action = action;
                paramsLog.data = data;
                paramsLog.date = moment().format('YYYY-MM-DD HH:mm:ss');
                //await LogService.create(paramsLog);
            },

            closeLoading(){
                let loadingInstance = Loading.service();
                loadingInstance.close();

                //this.$loading.close();
            }
            /*toggleNavbar() {
                document.body.classList.toggle('nav-open')
            },
            closeMenu() {
                document.body.classList.remove('nav-open')
                document.body.classList.remove('off-canvas-sidebar')
            },*/

        },
        beforeDestroy() {
            //this.closeMenu()
        }
    }
</script>
<style>
    .navbar-nav .nav-item p {
        line-height: inherit;
        margin-left: 5px;
    }
</style>
<style lang="scss">
    .vue-notifyjs.notifications{
        .alert{
            z-index: 100;
        }
        .list-move {
            transition: transform 0.3s, opacity 0.4s;
        }
        .list-item {
            display: inline-block;
            margin-right: 10px;

        }
        .list-enter-active {
            transition: transform 0.2s ease-in, opacity 0.4s ease-in;
        }
        .list-leave-active {
            transition: transform 1s ease-out, opacity 0.4s ease-out;
        }

        .list-enter {
            opacity: 0;
            transform: scale(1.1);

        }
        .list-leave-to {
            opacity: 0;
            transform: scale(1.2, 0.7);
        }
    }
    .btn{
        &.btn-login{
            background-color: #6B3E91;
            border-color: #6B3E91;
            &:hover{
                background-color: #19255B;
                border-color: #19255B;
            }

        }
    }

</style>
