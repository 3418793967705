
//import BackendService from "src/assets/js/services/BackendService";

export default class Helpers {//extends BackendService {

    /*constructor() {
      super();
    }*/

    getAll() {
        return JSON.parse(localStorage.getItem('properties'));

    }
    getSelected() {
        return JSON.parse(localStorage.getItem('property'));
    }
    updateSelected(property_id){

        let properties = this.getAll();

        let property = properties.filter(property => property._id === property_id)

        /*let info_property = {
            _id:property._id,
            logo:property.logo,
            name:property.name,
        };*/


        localStorage.setItem('property', JSON.stringify(property[0]));

        return property[0];

    }
    getPermissionsAll(){
        return ['Habitaciones y tarifas', 'Reservaciones', 'Marketing', 'Revenue', 'Contabilidad', 'Propiedad']
    }
    getKeyMap(){
        return 'AIzaSyBJNF6PMBUZHqRgg9CQeFG7sLCKMLE6b8g';
    }

    /*addItem(data) {
        let arr = JSON.parse(localStorage.getItem('properties'));
        let  itemcar = {};

        if(this.findIndex(arr, data.id)===-1){
            //console.log('Es un elemento nuevo');
            itemcar = {
                img: data.img,
                title: data.nombre,
                price: data.precio,
                qty: 1,
                total: data.precio,
                id: data.id
            };

            arr.push(itemcar);
        }else{
            itemcar = arr[this.findIndex(arr, data.id)];
            this.plusQty(itemcar);
            //console.log('Es un elemento existente');
        }
        localStorage.setItem('carItems', JSON.stringify(arr));
        this.getTotal();

        return JSON.stringify({
            msg: 'Producto añadido exitosamente',
            status: 200,
        });
    }
    /*removeItem(id) {
        console.log('removiendo item');
        let arr = JSON.parse(localStorage.getItem('carItems'));
        let arrKey = this.findIndex(arr, id);

        if (arrKey > -1) {
            arr.splice(arrKey, 1);
        }
        return arr;
    }
    deleteItem(id){
        let arr = this.removeItem(id);
        console.log(arr);

        if(arr === undefined){
            this.clearCar();
        }else{
            localStorage.setItem('carItems', JSON.stringify(arr));
            this.getTotal();
        }
    }*/

    findIndex(arr, id) {
        let obj = arr.find(o => o.id === id);
        return  arr.indexOf(obj);
    }
}
