
export const sidebarTranslate = {




    es: {
        home: 'Inicio',
        users: 'Usuarios',
        properties: 'Propiedad',
        resume_property: 'Resumen de la propiedad',
        data_property: 'Datos de la propiedad',
        rooms: 'Habitaciones',
        reservations: 'Reservaciones',
        contracts: 'Contratos',
        politics: 'Políticas de cancelacíon',
        terms: 'Políticas generales',
        rates: 'Tarifas y disponibilidad',
        configurations: 'Configuración',
        room_rates: 'Habitaciones y Tarifas',
        inventory_availability: 'Inventario y disponibilidad',
        rates_restrict: 'Tarifas y restricciones',
        marketing: 'Marketing',
        promotions: 'Promociones',
        landings: 'Landings',
        addons: 'Servicios extra',
        revenues: 'Revenue',
        tables_dynamic: 'Tarifas dinámicas',
        logs: 'Movimientos',
        integrations: 'Integración',
        integration_rateplans: 'Contratos conectados',
        integration_rooms: 'Habitaciones conectadas',
        integration:{
            ratetiger: 'Ratetiger'
        }
    },

    en: {
        home: 'Home',
        users: 'Users',
        properties: 'Properties',
        contracts: 'Contracts',

    },
};