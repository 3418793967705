
export const roomTranslate = {




    es: {

        index: {
            home: 'Inicio',
            property: 'Propiedad',
            title : 'Habitaciones',
            header : 'Administrar habitaciones',
            new: 'Agregar habitación',
            new_tooltip: 'Nueva habitación ',
            cod: 'Cod Habitación',
            contracts: 'Contratos',
            types: 'Tipo cama',
            has: 'Tiene',
            a_has_plural: 'habitaciones activas',
            a_has_singular: 'habitación activa',
            i_has_plural: 'habitaciones inactivas',
            i_has_singular: 'habitación inactiva',
            search: 'Buscar habitaciones'
        },
        new:{
            title : 'Agregar nueva habitación',
            header : 'Crear nueva habitación',
            modal: '¡Operación exitosa!',
            msg: 'Habitación guardada'
        },
        edit: {
            header : 'Editar habitación',
            button:  'Actualizar habitación',
            msg: 'Habitación actualizada'

        },
        form:{
            code: 'Código habitación',
            type_room: 'Tipo de habitacíon',
            look: 'Vista',
            images: 'Imagenes',
            pick_image: 'Selecciona imagen',
            surface: 'Superficie',
            room_number: 'No. de recamaras',
            bathroom_number: 'No. de baños',
            capacity_max: 'Capacidad máxima',
            adults_max: 'Máximo de adultos',
            kids_max: 'Máximo de niños',
            jrs_max: 'Máximo de Juniors',
            contract: 'Asignar contrato',
            values_add: 'Valores agregados',
            values_new: 'Ingresa otro valor agregado',
            add: 'Agregados',
            amenities_add: 'Código',
            amenities: 'Amenidades',
            bed_type: 'Tipo de cama',
            bed_number: 'Número de camas',
            bed_question: '¿Nécesitas un arreglo de camas diferentes?',
            array: 'Arreglo',
            image_suggest: 'Las medidas recomendadas para las imágenes son: 720px de ancho  por 500px de alto'
        },
        button:{
            value_add :'Agregar nuevo valor agregado',
            amenitie_add :'Agregar nueva amenidad',
            bed_add :'Agregar otro tipo de cama',
            array_add :'Agregar otro arreglo',
        }

    },

    en: {
    },
};