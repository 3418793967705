
export const annulmentTranslate = {




    es: {

        index: {
            home: 'Inicio',
            property: 'Propiedad',
            title : 'Políticas de cancelación',
            new: 'Agregar nueva política de cancelación',
            has: 'Tiene',
            a_has_plural: 'políticas de cancelación activas',
            a_has_singular: 'política de cancelación activa',
            i_has_plural: 'políticas de cancelación inactivas',
            i_has_singular: 'política de cancelación inactiva',
            search: 'Buscar políticas de cancelacion'
        },
        table:{
            model: 'Modelo de negocio',
            name: 'Nombre',
            contracts: 'Contratos activos',
            refundable: 'Tarifa reembolsable'

        },
        new:{
            title : 'Agregar nueva política de cancelación',
            header : 'Crear nueva política de cancelación',
            modal: '¡Operación exitosa!',
            msg: 'Política de cancelación guardada'
        },
        edit: {
            header : 'Editar política de cancelación',
            button:  'Actualizar política de cancelación',
            msg: 'Política de cancelación actualizada'

        },
        form:{
            code: 'Código contrato',
            type: 'Tipo de contrato',
            currency: 'Moneda',
            asign: 'Asignar a habitaciones',
            type_payment: 'Tipo de pago',
            politics: 'Políticas de cancelación',
            vinculo: 'Vinculo de contrato',
            refundable_exception: 'Fechas no reembolsables',
            season_exception: ' Excepciones de temporada',
            description: 'Descripción de la política de cancelación',
            intval_refundable:'AÑADIR INTEVALO DE FECHAS NO REEMBOLSABLE',
            intval_season:'AÑADIR INTEVALO DE FECHAS POR TEMPORADA O ALTA DEMANDA',
            period: 'Periodo de cancelación',
            inside: 'Dentro del periodo de penalización',
            outside: 'Fuera del periodo de penalización',
            rate_refundable: 'Esta tarifa no es reembolsable',
            politic_base: 'Establecer política de cancelación por defecto',
            config_calleation: 'Configuración de cancelaciones base',
            plans: 'Planes de tarifa',
            period_valid: 'periodo de cancelación',
            inside_valid: 'periodo de penalización',
            outside_valid: 'periodo de penalización',
        }

    },

    en: {
    },
};