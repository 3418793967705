<template>
  <div>
    <div class="wrapper wrapper-full-page">

      <div class="full-page" :data-color="backgroundColor" :class="pageClass" data-color="#19255b99" >
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple | black" -->
        <div class="content">
          <div class="container">
            <slot></slot>
          </div>
        </div>

        <div class="full-page-background" id="full-page-login" ></div>
        </div>
    </div>
  </div>
</template>
<script>
  import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'
  import Settings from "../../../js/helpers/Settings";
  let settings = new Settings();

  export default {
    components: {
      [CollapseTransition.name]: CollapseTransition
    },
    props: {
      pageClass: {
        type: String,
        default: 'login-page'
      },
      backgroundColor: {
        type: String,
        default: 'black'
      }
    },
    data () {
      return {
        showMenu: false
      }
    },
    methods: {
        setLocale(locale){
            this.$i18n.locale = locale;
            settings.change_locale(locale);

        },
      toggleNavbar () {
        //document.body.classList.toggle('nav-open')
      },
      closeMenu () {
        /*document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')*/
      }
    },
    beforeDestroy () {
      //this.closeMenu()
    }
  }
</script>
<style>
  .navbar-nav .nav-item p {
    line-height: inherit;
    margin-left: 5px;
  }
  .navbar-wrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  }
  .wrapper-full-page .navbar-toggler,
  .wrapper-full-page .navbar-collapse .navbar-nav{
    margin-right: 30px;
  }
  .navbar-collapse .navbar-nav .nav-link {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .navbar-collapse .navbar-nav .nav-item {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  #full-page-login{
    background: #283575;
  }
</style>
