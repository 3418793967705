
export const userTranslate = {




    es: {

        index: {
            property: 'Propiedad',
            title : 'Usuarios',
            header : 'Administrar usuarios',
            new: 'Agregar usuario',
            new_tooltip: 'Nuevo usuario',
            has: 'Tienes',
            a_has_plural: 'usuarios activos',
            a_has_singular: 'usuario activo',
            i_has_plural: 'usuarios inactivos',
            i_has_singular: 'usuario inactivo',
            search: 'Buscar usuarios'
        },
        new:{
            header : 'Crear nuevo usuario',
            modal: '¡Operación exitosa!',
            msg: 'Usuario guardado'
        },
        edit: {
            header : 'Editar usuario',
            button:  'Actualizar usuario',
            msg: 'Usuario actualizado'

        },
        roles:{
            title: 'Roles',
            rooms: 'Habitaciones y tarifas',
            marketing: 'Marketing',
            accounting: 'Contabilidad',
            reservations: 'Reservaciones',
            revenue: 'Revenue',
            properties: 'Propiedad'


        }

    },

    en: {
    },
};