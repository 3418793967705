import axios from "axios";
import {state} from "../store/modules/auth";

export const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL
  //  baseURL: 'http://localhost:3000'
});

export default {
  // LOGIN
  login(user) {
    return apiClient.post("/login", user);
  },

  /** USER */
  getUser(id) {
    return apiClient.get("/users/" + id);
  },

  /** PROPERTY */
  listProperties(){
      const token =  state.token;
      if (token) {
          apiClient.defaults.headers.common["token"] = token;
      }


      return apiClient.get('properties');
  },
  getProperty(property_id) {
    return apiClient.get('property/'+property_id);
  },
  async getPropertyAsync(property_id){
    let response = await apiClient.get('property/'+property_id);
    return new Promise((resolve, reject) => {
      if (response.status >= 200 && response.status < 300) {
          //console.log('Response with code: ' + response.status + '\nContent: ' + response.statusText);
          resolve(response);
      } else {
          reject(response);
      }
  })
  }
  
  
};
